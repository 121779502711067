import { 
    Row, 
    Col, 
    Button, 
    Form, 
    Input, 
    DropdownItem, 
    DropdownMenu, 
    DropdownToggle, 
    UncontrolledDropdown 
}                                           from "reactstrap"

import { 
    useEffect, 
    useState 
}                                           from "react"

import { 
    Filter, 
    Share2,
    RotateCcw, 
}                                           from "react-feather"

import SiaccAPI                             from "../../service/pages/siacc/index_"
import IcSorting                            from "../../assets/images/icons/svg/IcSorting"
import AgentReport                          from "./component/AgentReport"
import ModalFilter                          from "./component/ModalFilter"
import ModalOrderFilter                     from "./component/ModalOrderFilter"

const SIACC = () => {

    const [data, setData]                   = useState([])
    const [filter, setFilter]                = useState(false)
    const [sorting, setSorting]             = useState(false)
    const [keyword, setKeyword]             = useState(null)
    const [loading, setLoading]             = useState(true)
    const [pagination, setPagination]       = useState(false)
    const [selectOrder, setSelectOrder]     = useState(null)
    const [selectFilter, setSelectFilter]   = useState(null)
    const [selectOrderBy, setSelectOrderBy] = useState(null)

    const getData = async (data) => {
        setLoading(true)
        let params = {}
        if (!data?.no_param) {
            params = {
                page : data?.page ? data?.page : 1,
                sort : data?.sort ? data?.sort : selectOrder,
                sort_by : data?.sort_by ? data?.sort_by : selectOrderBy,
                keyword : data?.keyword ? data?.keyword : keyword, 
                status : data?.status ? data?.status : selectFilter
            }
        }


        console.log(selectFilter, selectOrder, selectOrderBy)

        SiaccAPI.getSiaccFeeds(params)
            .then((res) => {
                setLoading(false)
                setData(res?.data?.result)
                setPagination(res?.data?.pagination)
            }).catch((error) => {
                console.log(error)
            })

       
    }

    const onSearch = (e) => {
        e.preventDefault()
        getData({keyword : keyword})
    }

    useEffect(() => {
        getData()
        // setData(dummySiacc)
    }, [])

    return (
        <>
            <Row className="w-100">
                <Col xs={4}>
                <div
                    className=""
                >
                    <Button
                        size="sm"
                        color="primary"
                        className="btn-icon me-1"
                        onClick={() => {
                            setFilter(true)
                        }}
                    >
                        <Filter

                        />
                    </Button>

                    <Button
                        size="sm"
                        color="primary"
                        className="btn-icon me-1"
                        onClick={() => {
                            setSorting(true)
                        }}
                    >
                        <IcSorting

                        />
                    </Button>
                </div>
                </Col>
                <Col xs={3}>
                    
                
                </Col>
                <Col xs={5}>
                    <div
                        className="d-flex justify-content-end "
                    >
                        <div className="w-100 me-2">
                            <Form onSubmit={(e) => onSearch(e)}>
                                <Input className="round" onChange={(e) => setKeyword(e.target.value)} placeholder = 'Search...'/>
                            </Form>
                        </div>
                
                        
                        <UncontrolledDropdown className=''>
                            <DropdownToggle className='cursor-pointer btn btn-primary btn-icon me-1' tag='span'>
                                <Share2 size={18} />
                            </DropdownToggle>
                            <DropdownMenu start>
                                <DropdownItem className='d-flex align-items-center w-100'>
                                    Jadikan Laporan Ke LAPHASTUG/ LAOPSIN
                                </DropdownItem>
                                <DropdownItem className='d-flex align-items-center w-100'>
                                    Jadikan Laporan Ke LAPINHAR/ LAPSUS/ LAPINSUS
                                </DropdownItem>
                            </DropdownMenu>
                        </UncontrolledDropdown>

                        <Button
                            size="sm"
                            color="primary"
                            className="btn-icon"
                            onClick={() => {
                                setKeyword(null)
                                setSelectOrder(null)
                                setSelectFilter(null)
                                setSelectOrderBy(null)
                                setFilter(false)
                                getData({no_param : true})
                            }}
                        >
                            <RotateCcw

                            />
                        </Button>
                    </div>
                </Col>
            </Row>

            <AgentReport 
                data = {data}
                getData = {getData}
                keyword = {keyword}        
                loading = {loading}
                pagination = {pagination}
                setPagination = {setPagination}

            />

            {/* <AgentReportFilter
                show        = {filter}
                setShow     = {setFilter}
                size        = "md"
                title       = "Filter"
            /> */}

            <ModalFilter
                show        	= {filter}
                setShow     	= {setFilter}
                size        	= "sm"
                title       	= "Filter Status"
                getData         = {getData} 
                selectFilter    = {selectFilter}
                setSelectFilter = {setSelectFilter}
            />

            <ModalOrderFilter
                show        	= {sorting}
                setShow     	= {setSorting}
                size        	= "sm"
                selectOrder     = {selectOrder}
                setSelectOrder  = {setSelectOrder}
                selectOrderBy   = {selectOrderBy}
                setSelectOrderBy = {setSelectOrderBy}
                title       	= "Order"
                getData         = {getData} 
            />
        </>
    )
}

export default SIACC