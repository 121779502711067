import { useEffect, useState }      from "react"
import { Button }                   from "reactstrap"
import { Edit2, Slash, Trash }      from "react-feather"
import DataTablesGlobal             from "../../../../@core/components/data-tables-global"
import ModalField                   from "../../../other/ModalField"

const PositionTable = ({
    data,
	params,
	getData,
	loading,
	setParams,
	pagination,
	setIsCreateModalVisible,
	setIsUpdateModalVisible,
	setIsDeleteModalVisible,
	
    selected,
	setSelected,
	clearSelected,
	setClearSelected
}) => {
    
    const [hideColumn, setHideColumn]                       = useState([])
    const [isFilterModalVisible, setIsFilterModalVisible]   = useState(false) 
	const [isHeaderConfigVisible, setIsHeaderConfigVisible] = useState(false) 

    const header        = [      
        {
			omit            : hideColumn[0] === false,
			name            : 'No',
			center          : false,
			wrap            : true,
            compact         : true,
            width           : '50px',
            cell            : row => (
                <>
                    {(row.index) + ((pagination.page - 1) * pagination.page_size)}
                </>
            ),
			sortable		: true
		},
		{
			omit            : hideColumn[1] === false,
			name            : 'Nama',
			center          : false,
			wrap            : false,
            compact         : true,
			selector        : row => row.name,
			sortable		: true
		},
        {
			omit            : hideColumn[2] === false,
			name            : 'Satuan Kerja',
			center          : false,
			wrap            : true,
            compact         : true,
			selector        : row => row?.work_unit_level?.name,
            cell            : (row) => (
				<>
					{row?.work_unit_level?.name} {row?.work_unit?.name}
				</>
			),
			sortable		: true
		},
        {
			omit            : hideColumn[3] === false,
			name            : 'Aksi',
			center          : true,
			wrap            : true,
            compact         : true,
			selector        : row => (
                <>
                    <Button
                        color   = "light"
                        size    = "sm"
                        onClick = {() => {
                            setClearSelected(true)
							setSelected([row])
                            setIsUpdateModalVisible(true)
                        }}
                    >
                        <Edit2
                            size        = {17}
                            className   = "text-primary"
                        />
                    </Button>

                    <Button
                        color   = "light"
                        size    = "sm"
                        onClick = {() => {
                            setClearSelected(true)
							setSelected([row])
                            setIsDeleteModalVisible(true)
                        }}
                    >
                        <Trash
                            size        = {17}
                            className   = "text-primary"
                        />
                    </Button>
                </>
            ),
			sortable		: true
		}
	]

    useEffect(() => {
		setHideColumn(() => header.map(() => true))
	}, [])

    return (
        <>
            <DataTablesGlobal
                data               		= {data}
                limit              		= {pagination.page_size}
                header             		= {header}
                params             		= {params}
                getData            		= {getData}
                loading            		= {loading}
                selected           		= {selected}
                totalData          		= {pagination.count}
                setParams          		= {setParams}
                setSelected        		= {setSelected}
                toggledClearRows   		= {clearSelected}
				setIsCreateModalVisible = {setIsCreateModalVisible}
				setIsDeleteModalVisible = {setIsDeleteModalVisible}
				setIsFilterModalVisible = {setIsFilterModalVisible}
                setIsHeaderConfigVisible= {setIsHeaderConfigVisible}
				visibility  	   		= {{
					filter          : true,
					delete			: true,
                    create          : true,
                    header_config   : true,
					search 			: true
                }}
            />

            <ModalField
                show        	= {isHeaderConfigVisible}
                setShow     	= {setIsHeaderConfigVisible}
                size        	= "sm"
                title       	= "Select"
                data        	= {header}
				hideColumn  	= {hideColumn}
				setHideColumn 	= {setHideColumn}
            />
        </>
    )
}

export default PositionTable