import { 
    Button,
    Form,
    FormFeedback,
    Input,
    InputGroup, 
    InputGroupText, 
    Label, 
    Media, 
    Modal, 
    ModalBody, 
    ModalFooter, 
    ModalHeader 
}                           from "reactstrap"

import { 
    Controller, 
    useForm 
}                           from "react-hook-form"

import { 
    useContext, 
    useEffect, 
    useRef, 
    useState 
}                           from "react"

import { 
    UserManagementSchema, 
    UserManagementSchemaNonDirChild 
}                           from "./validation"

import Select               from 'react-select'
import SelectSector 	    from "@components/select-sector"
import SelectBidang 		from "@components/select-bidang"
import SelectSubdirektorat  from "@components/select-subdirektorat"
import SelectSeksi          from "@components/select-seksi"
import toast                from "react-hot-toast"

import { SectorContext }    from "../../../../context/sectorContext"
import { WorkunitContext }  from "../../../../context/workunitContext"
import { PositionContext }  from "../../../../context/positionContext"
import { GradeContext }     from "../../../../context/gradeContext"
import { ClassesContext }   from "../../../../context/classesContext"
import { RoleContext }      from "../../../../context/roleContext"
import { yupResolver }      from "@hookform/resolvers/yup"

import baseURL              from "../../../../service"
import Helper               from "../../../../helper"
import InputPasswordToggle  from "../../../../@core/components/input-password-toggle"
import userManagementAPI    from "../../../../service/pages/user-management"
import LabelRequired from "../../../../@core/components/label-required"
import { Eye, EyeOff } from "react-feather"

const UserManagementUpdate = ({
    show,
    setShow,
    title,
    size,
    data,
    handleUpdate
}) => {

    const fileInputRef          = useRef(null)
    const { fallbackImage_ }    = Helper

    const [account, setAccount]                          = useState(false)
    const [refresh, setRefresh]                          = useState(false)
    const [validasiPassword, setValidasiPassword]        = useState(false)
    const [showPassword1, setShowPassword1]              = useState(false)
    const [showPassword2, setShowPassword2]              = useState(false)
    const [passwordData, setPasswordData]                = useState({
        username            : "",
        new_password        : "",
        confirm_password    : ""
    })

    const [errAccount, setErrAccount]                       = useState({
        username            : "",
        new_password        : "",
        confirm_password    : ""
    })
    
    const [validationEmployee, setValidationEmployee]       = useState(null)
    const [loadingCheckEmployee, setLoadingCheckEmployee]   = useState(false)
    const [errEmployee, setErrEmployee]                     = useState({
        nip   : "",
        phone : "",
        email : ""
    })

    const [showDirektoratChild, setShowDirektoratChild]  = useState(true)

    const [profilePicture, setProfilePicture]             = useState(null)
    const [profilePictureBase64, setProfilePictureBase64] = useState(null)
    const [positionFiltered, setPositionFiltered]         = useState([])

    const {
		reset,
        getValues,
        watch,
		control,
		setValue,
		setError,
		clearErrors,
		handleSubmit,
		formState: { errors },
	} = useForm({
		mode        : "onChange",
        resolver    : yupResolver(
            showDirektoratChild ? UserManagementSchema : UserManagementSchemaNonDirChild
        ),
    })

    const {
        roleOption
    } = useContext(RoleContext)

    const {
        sectorOption
    } = useContext(SectorContext)

    const {
        gradeOption
    } = useContext(GradeContext)

    const {
        classesOption
    } = useContext(ClassesContext)

    const {
        workunitOption
    } = useContext(WorkunitContext)

    const {
        positionOption,
        getDataPositionByWorkunit
    } = useContext(PositionContext)


    const handleFileSelect = () => {
        fileInputRef.current.click()
    }

    const removeSymbols = (e) => {
        const str       = e.target.value
        const regex     = /[^a-zA-Z.,\s]/g
        const temp      = str.replace(regex, '')
        return temp
    }

    const handleCheckEmployeeData = async () => {
        setLoadingCheckEmployee(true)
        let _validation = null
        const tempErrEmployee = errEmployee

        if (watch("nip") && watch("nip") !== "") {
            await userManagementAPI.checkEmployee({
                field : "nip",
                value : watch("nip"),
                id    : data?.id
            })
            .then((res) => {
                _validation = true
                tempErrEmployee.nip = ""
            })
            .catch((err) => {
                _validation = false
                tempErrEmployee.nip = "NIP Telah Digunakan!"
            })
        }

        if (watch("email") && watch("email") !== "") {
            await userManagementAPI.checkEmployee({
                field : "email",
                value : watch("email"),
                id    : data?.id
            })
            .then((res) => {
                _validation = true
                tempErrEmployee.email = ""
            })
            .catch((err) => {
                _validation = false
                tempErrEmployee.email = "Email Telah Digunakan!"
            })
        }

        if (watch("phone_number") && watch("phone_number") !== "") {
            await userManagementAPI.checkEmployee({
                field : "phone_number",
                value : watch("phone_number"),
                id    : data?.id
            })
            .then((res) => {
                _validation = true
                tempErrEmployee.phone = ""
            })
            .catch((err) => {
                _validation = false
                tempErrEmployee.phone = "Nomor Telepon Telah Digunakan!"
            })
        }

        setErrEmployee({...tempErrEmployee})

        if (_validation !== null) {
            setValidationEmployee(_validation)
        }
        setLoadingCheckEmployee(false)

        return _validation
    }

    const onSubmit = (data) => {
        if (handleCheckEmployeeData()) {
            handleUpdate(data, profilePicture)
        }
    }

    const handleChangeInputPassword = (value) => {
        setPasswordData(value)
        setRefresh(true)
    }

    const handleChangePassword = () => {
        setValidasiPassword(true)

        const err = errAccount

        if (
            passwordData?.new_password      === "" ||
            passwordData.confirm_password   === ""
        ) {
            if (passwordData.new_password === "") {
                err.new_password = "Password Wajib Diisi!"
            }
            
            if (passwordData.confirm_password === "") {
                err.confirm_password = "Password Wajib Diisi!"
            }

            setErrAccount({
                ...err
            })
        } else {
            if (passwordData?.new_password?.length < 8) {
                setErrAccount({
                    ...err,
                    new_password : "Minimal jumlah karakter adalah 8!"
                })
            } else if (passwordData?.new_password !== passwordData?.confirm_password) {
                setErrAccount({
                    ...err,
                    new_password : "Kata Sandi Baru dan Konfirmasi Kata Sandi Tidak Sama!"
                })
            } else {
                const params = {
                    user_id : data?.user_id
                }

                userManagementAPI.changePasword(passwordData, params)
                    .then((res) => {
                        toast.success("Kata Sandi Berhasil Diubah")
                        setAccount(false)
                        setShow(false)
                    })
                    .catch((err) => {
                        toast.error(`Kata Sandi Gagal Diubah!`)
                    })
            }
        }
    }

    useEffect(() => {
        if (refresh) {
            setRefresh(false)
        }
    }, [refresh])

    useEffect(() => {
        reset()
        
        // if (positionOption?.length > 0) {
        //     const selected = positionOption?.filter((dt) => dt.value === data?.position_id)
        //     if (selected?.length > 0) {
        //         setValue("position", selected[0])
        //     }
        // }

        setValue("workunit", {
            label : `${data?.work_unit?.work_unit_level?.name} ${data?.work_unit?.name}`,
            value : data?.work_unit_id
        })

        if (gradeOption?.length > 0) {
            const selected = gradeOption?.filter((dt) => dt.value === data?.grade?.id)
            if (selected?.length > 0) {
                setValue("grade", selected[0])
            }
        }

        if (classesOption?.length > 0) {
            const selected = classesOption?.filter((dt) => dt.value === data?.classes?.id)
            if (selected?.length > 0) {
                setValue("class", selected[0])
            }
        }

        if (sectorOption?.length > 0) {
            const selected = sectorOption?.filter((dt) => dt.value === data?.sector_id)
            if (selected?.length > 0) {
                setValue("sector", selected[0])
            }
        }

        if (roleOption?.length > 0) {
            const selected = roleOption?.filter((dt) => dt.value === data?.role_id)
            if (selected?.length > 0) {
                setValue("role", selected[0])
            }
        }

        if (data?.code_id) {
            setValue("bidang", data?.code_id)
        }
       
        if (data?.code_sector_id) {
            setValue("subdirektorat", data?.code_subdir_id)
        }

        if (data?.code_sector_id) {
            setValue("subdirektorat", data?.code_subdir_id)
        }

        if (data?.code_sector_id) {
            setValue("seksi", data?.code_seksi_id)
        }

        if (data?.code_sector_id) {
            setValue("sektor", data?.code_sector_id)
        }

        if (data?.user?.username) {
            const _data          = passwordData
            _data.username       = data?.user?.username
            handleChangeInputPassword(_data)
        }

        if (data?.photo) {
            const url = baseURL.replace("/api", "")
            setProfilePictureBase64(`${url}${data?.photo}`)
        }
        
        setValue("is_superuser", data?.user?.is_superuser)
    }, [data])

    useEffect(() => {
        setErrAccount({
            username  : "",
            password1 : "",
            password2 : ""
        })
    }, [account])

    useEffect(() => {
        // const temp = positionOption.filter((dt) => dt.level === watch('workunit')?.level)
        // const temp = positionOption.filter((dt) => dt.work_unit_id === watch('workunit')?.value)
        // setPositionFiltered(temp)

        if (watch('workunit')?.value) {
            getDataPositionByWorkunit(watch('workunit')?.value)
        }

        // setValue("position", null)
        setTimeout(() => {
            setValue("position", undefined)
        }, 500)
    }, [watch('workunit')])

    useEffect(() => {
        setTimeout(() => {
            if (positionOption?.length > 0) {
                // const temp = positionOption.filter((dt) => dt.level === data?.work_unit?.work_unit_level_id)
                const temp = positionOption.filter((dt) => dt.work_unit_id === watch('workunit')?.value)
                setPositionFiltered([...temp])
    
                const selected = positionOption?.filter((dt) => dt.value === data?.position_id)
                if (selected?.length > 0) {
                    setValue("position", selected[0])
                }
            }
        }, 1000)
    }, [positionOption, data])

    useEffect(() => {
        console.log("positionFiltered", positionFiltered)
    }, [positionFiltered])

    return (
        <>
            <Modal
                isOpen  = {show} 
                toggle  = {() => { setShow(!show) }} 
                size    = {size || "sm"}
            >
                <ModalHeader 
                    toggle={() => setShow(!show)} 
                > 
                    {title}
                </ModalHeader>
                <Form onSubmit={handleSubmit(onSubmit)}>
                    <ModalBody>
                        <div>
                            <div
                                className="d-flex col-12 p-0 m-0"
                            >
                                <div
                                    className="col-6 pe-1 p-0 m-0"
                                >
                                    <div
                                        className   = "mb-1 m-0 p-0"
                                    >
                                        <Label>Foto Profil</Label>

                                        <div
                                            className = ""
                                        >
                                            <img
                                                src         = {profilePictureBase64 ? profilePictureBase64 : `https://ui-avatars.com/api/?name=${getValues('name') ? watch("name") : "-"}&background=4e73df&color=fff&bold=true`}
                                                onError     = {fallbackImage_}
                                                width       = "113"
                                                height      = "113"
                                                className   = "rounded mr-50"
                                            />

                                            <Button.Ripple
												color		= "primary"
                                                className   = "ms-1"
                                                onClick     = {handleFileSelect}
											>
							    				Pilih Foto
    										</Button.Ripple>

                                            <input
                                                type            = "file"
                                                accept          = "image/*"
                                                ref             = {fileInputRef}
                                                style           = {{ display: 'none' }}
                                                onChange        = {(e) => {
                                                    const selectedFile = e.target.files[0]
                                                    setProfilePicture(selectedFile)

                                                    const reader = new FileReader()
                                                    reader.onload = () => {
                                                        setProfilePictureBase64(reader.result)
                                                    }
                                                    reader.readAsDataURL(selectedFile)
                                                }}
                                            />
                                        </div>
                                    </div>

                                    <div
                                        className   = "mb-1"
                                    >
                                        <Label>NIP/NRP <LabelRequired /> </Label>
                                        <Controller
                                            name        = "nip"
                                            control     = {control}
                                            render      = {({ field }) => (
                                                <Input
                                                    {...field} 
                                                    invalid             = {errors.nip && true}
                                                    placeholder         = "NIP/NRP"
                                                />
                                            )}
                                            defaultValue= {data?.nip}
                                        />
                                        {errors.nip && <small className="text-danger">{errors.nip.message}</small>}
                                        {errEmployee.nip !== "" && loadingCheckEmployee ? <small className="text-primary">Sedang Memeriksa NIP...</small> : <small className="text-danger">{errEmployee.nip}</small>}
                                    </div>

                                    <div
                                        className   = "mb-1"
                                    >
                                        <Label>Nama Lengkap <LabelRequired /> </Label>
                                        <Controller
                                            name        = "name"
                                            control     = {control}
                                            render      = {({ field }) => (
                                                <Input
                                                    {...field} 
                                                    invalid             = {errors.name && true}
                                                    placeholder         = "Nama Lengkap"
                                                    defaultValue        = {data?.name}
                                                    onChange            = {(e) => {
                                                        const temp = removeSymbols(e)
                                                        field.onChange(temp)
                                                    }}     
                                                />
                                            )}
                                            defaultValue= {data?.name}
                                        />
                                        {errors.name && <small className="text-danger">{errors.name.message}</small>}
                                    </div>

                                    <div
                                        className   = "mb-1"
                                    >
                                        <Label>Pangkat <LabelRequired /> </Label>
                                        <Controller
                                            name        = "grade"
                                            control     = {control}
                                            render      = {({ field }) => (
                                                <Select
                                                    {...field} 
                                                    className           = {`react-select ${errors.grade?.value && "is-invalid"}`}
                                                    classNamePrefix     = "select"
                                                    placeholder         = "Pangkat"
                                                    options             = {gradeOption}
                                                />
                                            )}
                                            defaultValue= {data?.grade}
                                        />
                                        {errors.grade && <small className="text-danger">{errors.grade?.value?.message}</small>}
                                    </div>

                                    <div
                                        className   = "mb-0"
                                    >
                                        <Label>Golongan <LabelRequired /> </Label>
                                        <Controller
                                            name        = "class"
                                            control     = {control}
                                            render      = {({ field }) => (
                                                <Select
                                                    {...field} 
                                                    className           = {`react-select ${errors.class?.value && "is-invalid"}`}
                                                    classNamePrefix     = "select"
                                                    placeholder         = "Golongan"
                                                    options             = {classesOption}
                                                />
                                            )}
                                            defaultValue= {data?.class}
                                        />
                                        {errors.class && <small className="text-danger">{errors.class?.value?.message}</small>}
                                    </div>

                                </div>
                                <div
                                    className="col-6 ps-1"
                                >
                                    
                                    <div
                                        className   = "mb-1"
                                    >
                                        <div
                                            className   = "mb-1"
                                        >
                                            <Label>Satuan Kerja <LabelRequired /> </Label>
                                            <Controller
                                                name        = "workunit"
                                                control     = {control}
                                                render      = {({ field }) => (
                                                    <Select
                                                        {...field} 
                                                        className           = {`react-select ${errors.workunit?.value && "is-invalid"}`}
                                                        classNamePrefix     = "select"
                                                        placeholder         = "Satuan Kerja"
                                                        options             = {workunitOption}
                                                    />
                                                )}
                                                defaultValue= {data?.workunit}
                                            />
                                            {errors.workunit && <small className="text-danger">{errors.workunit?.value?.message}</small>}
                                        </div>
                                        <Label>Jabatan <LabelRequired /> <small className="text-primary"></small></Label>
                                        <Controller
                                            name        = "position"
                                            control     = {control}
                                            render      = {({ field }) => (
                                                <Select
                                                    {...field} 
                                                    className           = {`react-select ${errors.position?.value && "is-invalid"}`}
                                                    classNamePrefix     = "select"
                                                    // placeholder         = "Jabatan"
                                                    options             = {positionOption}
                                                    placeholder         = {`
                                                        ${!watch("workunit") ?
                                                            "Jabatan"
                                                        : (watch("workunit") && positionOption?.length) === 0 ? 
                                                            "Memuat Jabatan..." 
                                                        : "Pilih Jabatan" }
                                                    `}
                                                    isDisabled          = {(!watch("workunit") || positionOption?.length === 0)}
                                                    // isDisabled          = {(!watch("workunit") || positionOption?.length < 1)}
                                                />
                                            )}
                                            defaultValue= {data?.position}
                                        />
                                        {errors.position && <small className="text-danger">{errors.position?.value?.message}</small>}
                                    </div>

                                    <div
                                        className   = "mb-1"
                                    >
                                        <Label>Privilage Role <LabelRequired /> </Label>
                                        <Controller
                                            name        = "role"
                                            control     = {control}
                                            render      = {({ field }) => (
                                                <Select
                                                    {...field} 
                                                    className           = {`react-select ${errors.role?.value && "is-invalid"}`}
                                                    classNamePrefix     = "select"
                                                    placeholder         = "Privilage Role"
                                                    options             = {roleOption}
                                                />
                                            )}
                                            defaultValue= {data?.role}
                                        />
                                        {errors.role && <small className="text-danger">{errors.role?.value?.message}</small>}
                                    </div>


                                    <div
                                        className   = "mb-1"
                                    >
                                        <Label>Struktur/Bidang <LabelRequired /> </Label>
                                        <Controller
                                            name        = "sector"
                                            control     = {control}
                                            render      = {({ field }) => (
                                                <Select
                                                    {...field} 
                                                    className           = {`react-select ${errors.sector?.value && "is-invalid"}`}
                                                    classNamePrefix     = "select"
                                                    placeholder         = "Struktur/Bidang"
                                                    options             = {sectorOption}
                                                />
                                            )}
                                            defaultValue= {data?.sector}
                                        />
                                        {errors.sector && <small className="text-danger">{errors.sector?.value?.message}</small>}
                                    </div>

                                    <div
                                        className   = "my-1"
                                    >
                                        <Label>No. Telepon<LabelRequired />  <small className="text-primary">(Harus Terhubung Dengan Whatsapp)</small></Label>
                                        <Controller
                                            name        = "phone_number"
                                            control     = {control}
                                            render      = {({ field }) => (
                                                <Input
                                                    {...field} 
                                                    invalid             = {errors.phone_number && true}
                                                    placeholder         = "No. Telepon"
                                                    defaultValue        = {data?.name}
                                                />
                                            )}
                                            defaultValue= {data?.phone_number}
                                        />
                                        {errors.phone_number && <small className="text-danger">{errors.phone_number.message}</small>}
                                        {errEmployee.phone !== "" && loadingCheckEmployee ? <small className="text-primary">Sedang Memeriksa Nomor Telepon...</small> : <small className="text-danger">{errEmployee.phone}</small>}
                                    </div>
                                    
                                    <div
                                        className   = "mb-0"
                                    >
                                        <Label>Surel <LabelRequired /> </Label>
                                        <Controller
                                            name        = "email"
                                            control     = {control}
                                            render      = {({ field }) => (
                                                <Input
                                                    {...field} 
                                                    invalid             = {errors.email && true}
                                                    placeholder         = "Surel"
                                                    defaultValue        = {data?.name}
                                                />
                                            )}
                                            defaultValue= {data?.email}
                                        />
                                        {errors.email && <small className="text-danger">{errors.email.message}</small>}
                                        {errEmployee.email !== "" && loadingCheckEmployee ? <small className="text-primary">Sedang Memeriksa Email...</small> : <small className="text-danger">{errEmployee.email}</small>}
                                    </div>
                                </div>
                            </div>
                            
                            <div
                                className="mt-1 text-dark"
                            >
                                <Controller
                                    name        = "is_superuser"
                                    control     = {control}
                                    render      = {({ field }) => (
                                        <Input
                                            {...field} 
                                            invalid     = {errors.is_superuser && true}
                                            type        = "checkbox"
                                            className   = "me-1 cursor-pointer"
                                            checked     = {field.value}
                                        />
                                    )}
                                />
                                <Label>Jadikan Super Admin?</Label>
                            </div>

                            <div className="my-1">
								<Label className="form-label" for="bidang">
							    	Pilih Direktorat <LabelRequired /> 
								</Label>
								<Controller
									id		= "bidang"
									name	= "bidang"
									render	= {({ field }) => (
										<SelectBidang
											field		= {field}
											onChange    = {(bidang) => { 
                                                field.onChange(bidang.value) 
                                                if (bidang.label === "Sekretariat Jaksa Agung Muda Bidang Intelijen") {
                                                    setShowDirektoratChild(false)
                                                } else {
                                                    setShowDirektoratChild(true)
                                                }
                                            }}
											className   = {`react-select ${errors.bidang && "is-invalid"}`}
                                            placeholder = "Pilih Direktorat"
										/>
									)}
									control ={control}
								/>
								{errors.bidang && (<small className="text-danger">{errors.bidang.message}</small>)}
							</div>

                            {
                                showDirektoratChild &&
                                    <>
                                        <div className="my-1">
                                            <Label className="form-label" for="subdirektorat">
                                                Pilih Subdirektorat <LabelRequired /> 
                                            </Label>
                                            <Controller
                                                id		= "subdirektorat"
                                                name	= "subdirektorat"
                                                render	= {({ field }) => (
                                                    <SelectSubdirektorat
                                                        field	   = {field}
                                                        bidangId   = {watch('bidang')}
                                                        onChange   = {(subdirektorat) => { field.onChange(subdirektorat.value) }}
                                                        className  = {`react-select ${errors.subdirektorat && "is-invalid"}`}
                                                    />
                                                )}
                                                control = {control}
                                            />
                                            {errors.subdirektorat && (<small className="text-danger">{errors.subdirektorat.message}</small>)}
                                        </div>

                                        <div className="my-1">
                                            <Label className="form-label" for="seksi">
                                                Pilih Seksi <LabelRequired /> 
                                            </Label>
                                            <Controller
                                                id		= "seksi"
                                                name	= "seksi"
                                                render	= {({ field }) => (
                                                    <SelectSeksi
                                                        field	            = {field}
                                                        subdirektoratId	    = {watch('subdirektorat')}
                                                        onChange            = {(seksi) => { field.onChange(seksi.value) }}
                                                        className           = {`react-select ${errors.seksi && "is-invalid"}`}
                                                    />
                                                )}
                                                control ={control}
                                            />
                                            {errors.seksi && (<small className="text-danger">{errors.seksi.message}</small>)}
                                        </div>

                                        <div className="my-1">
                                            <Label className="form-label" for="sector">
                                                Pilih Sektor <LabelRequired /> 
                                            </Label>
                                            <Controller
                                                defaultValue= ""
                                                control     = {control}
                                                id          = "sektor"
                                                name        = "sektor"
                                                render      ={({ field }) => (
                                                    <SelectSector
                                                        field		= {field}
                                                        seksiId	    = {watch('seksi')}
                                                        onChange 	= {(sector) => { field.onChange(sector.value) }}
                                                        className	= {`react-select ${errors.sektor && "is-invalid"}`}
                                                    />
                                                )}
                                            />
                                            {errors.sektor && (<small className="text-danger">Isian Tidak Boleh Kosong</small>)}
                                        </div>
                                    </>
                            }
                            
                            <div
                                className   = "mb-0"
                                >
                                <Label>Alamat <LabelRequired /> </Label>
                                <Controller
                                    name        = "address"
                                    control     = {control}
                                    render      = {({ field }) => (
                                        <Input
                                            {...field} 
                                            invalid             = {errors.address && true}
                                            type                = "textarea"
                                            placeholder         = "Alamat"
                                        />
                                    )}
                                    defaultValue= {data?.address}
                                />
                                {errors.address && <small className="text-danger">{errors.address.message}</small>}
                            </div>
                        </div>
                    </ModalBody>

                    <ModalFooter>
                        <div
                            className   = "d-flex justify-content-between col-12"
                        >
                            <Button
                                color   = "primary"
                                outline
                                onClick = {() => {
                                    setShow(false)
                                }}
                            >
                                Batal
                            </Button>

                            <Button
                                outline
                                color   = "primary"
                                onClick = {() => {
                                    setAccount(true)
                                }}
                            >
                                Ubah Kata Sandi
                            </Button>

                            <Button
                                color   = "primary"
                                type    = "submit"
                            >
                                Simpan
                            </Button>
                        </div>
                    </ModalFooter>
                </Form>
            </Modal>

            <Modal
                isOpen  = {account} 
                toggle  = {() => { setAccount(!show) }} 
                size    = "sm"
            >
                <ModalHeader 
                    toggle={() => setAccount(!show)} 
                > 
                    Ubah Kata Sandi
                </ModalHeader>
                <ModalBody>
                    <div
                        className   = "mb-1"
                    >
                        <Label>Username</Label>
                        <Controller
							control	= {control}
							render	= {({ field }) => (
								<Input 
									{...field} 
									className	            = 'input-group-merge' 
									placeholder 	        = 'Username'
                                    value                   = {passwordData?.username}
                                    disabled                = {true}
                                    autoComplete            = {"new-password"}
								/>
							)}
						/>
                    </div>
                    <div
                        className   = "mb-1"
                    >
                        <Label>Kata Sandi Baru</Label>
                        <Controller
							control	= {control}
							render	= {({ field }) => (
								<InputGroup>
                            
                                    <Input 
                                        {...field} 
                                        className	            = 'input-group-merge' 
                                        placeholder 	        = 'Kata sandi Baru'
                                        type                    = {showPassword1 ? "text" : "password"}
                                        // invalid                 = {(errAccount && errAccount?.new_password !== "")}
                                        onChange                = {(e) => {
                                            const data          = passwordData
                                            data.new_password   = e.target.value
                                            handleChangeInputPassword(data)

                                            if (e.target.value !== "") {
                                                setErrAccount({
                                                    ...errAccount,
                                                    new_password : ""
                                                })
                                            }
                                        }}
                                        autoComplete            = {"new-password"}
                                    />
                                    <InputGroupText>
                                        <Button 
                                            color="link" 
                                            size="sm"
                                            className="btn-icon m-0 p-0"
                                            onClick={() => {
                                                setShowPassword1(!showPassword1)
                                            }}
                                        >
                                            {showPassword1 ? <EyeOff size={14}  /> : <Eye size={14} />}
                                        </Button>
                                    </InputGroupText>
                                </InputGroup>
							)}
						/>
                        {
                            errAccount.new_password && errAccount.new_password !== "" ?  <div className="text-danger">{errAccount.new_password}</div> : ""
                        }
                        {/* { validasiPassword && passwordData?.new_password === "" ? <div className="text-danger">Isian Tidak Boleh Kosong!</div> : "" } */}
                    </div>

                    <div
                        className   = "mb-1"
                    >
                        <Label>Ulangi Kata Sandi Baru</Label>
                        <Controller
							control	= {control}
							render	= {({ field }) => (
								<InputGroup>
                                    <Input 
                                        {...field} 
                                        className	            = 'input-group-merge' 
                                        placeholder 	        = 'Ulangi Kata sandi Baru'
                                        type                    = {showPassword2 ? "text" : "password"}
                                        // invalid                 = {(errAccount && errAccount?.confirm_password !== "")}
                                        onChange                = {(e) => {
                                            const data          = passwordData
                                            data.confirm_password   = e.target.value
                                            handleChangeInputPassword(data)

                                            if (e.target.value !== "") {
                                                setErrAccount({
                                                    ...errAccount,
                                                    confirm_password : ""
                                                })
                                            }
                                        }}
                                        autoComplete            = {"new-password"}
                                    />
                                    <InputGroupText>
                                        <Button 
                                            color="link" 
                                            size="sm"
                                            className="btn-icon m-0 p-0"
                                            onClick={() => {
                                                setShowPassword2(!showPassword2)
                                            }}
                                        >
                                            {showPassword2 ? <EyeOff size={14}  /> : <Eye size={14} />}
                                        </Button>
                                    </InputGroupText>
                                </InputGroup>
							)}
						/>
                        {
                            errAccount.new_password && errAccount.confirm_password !== "" ?  <div className="text-danger">{errAccount.confirm_password}</div> : ""
                        }
                        {/* { validasiPassword && passwordData?.confirm_password === "" ? <div className="text-danger">Isian Tidak Boleh Kosong!</div> : "" } */}
                    </div>

                    <div>
                        <Button
                            color           = "primary"
                            className       = "col-12"
                            onClick         = {() => {
                                handleChangePassword()
                            }}
                        >
                            Ubah
                        </Button>
                    </div>
                </ModalBody>
            </Modal>
        </>
    )
}

export default UserManagementUpdate