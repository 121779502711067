import { selectThemeColors } from "@utils"
import { useEffect, useState } from "react"
import Select 		from "react-select"
import classNames 	from "classnames"

import reportAPI from "../../../service/report"

const SelectBidang = (props) => {

	const [fieldOptions, setFieldOptions] = useState(null)

	const getData = () => {
		
		const params = {
			source 		: 'bidang',
			pageSize 	: 100
		}

		reportAPI.getAdditionalData(params).then(
			(res) => {
				const fieldOptions_ = []

				if (res.results) {
					res.results.map((data) => {
						if (data?.name === "Sekretariat Jaksa Agung Muda Bidang Intelijen") {
							if (props?.show_jam) {
								fieldOptions_.push({
									label : data.name,
									value : data.id
								})
							}
						} else {
							fieldOptions_.push({
								label : data.name,
								value : data.id
							})
						}
					})
				}

				setFieldOptions(fieldOptions_)
			}	
		).catch(() => {})
	}

	useEffect(() => {
		if (fieldOptions === null) {
			getData()
		}
	}, [fieldOptions])

	return (
		<Select
			value			= {fieldOptions && fieldOptions.find(opt => opt.value === props.field.value || opt.label === props.field.value)}
			theme			= {selectThemeColors}
			styles			= {{ menu: base => ({ ...base, zIndex: 9999 }) }}
			options			= {fieldOptions}
			isDisabled		= {props?.disabled}
			className		= {classNames("react-select", { "is-invalid": props.invalid })}
			placeholder		= {props?.placeholder ? props?.placeholder : "Pilih Bidang"}
			classNamePrefix	= "select"
			id 				= "selectBidang"
			onChange		= {(e) => {
				props.onChange(e)
			}}
			{...props}
		/>
	)
}

export default SelectBidang
