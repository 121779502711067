import { 
    Card, 
    CardBody,
    CardHeader, 
}               from "reactstrap"

import Avatar   from '@components/avatar'
import { useEffect, useState } from "react"
import PerformanceAPI from "../../../service/pages/performance"
import toast from "react-hot-toast"
import moment from "moment"
import Helper from "../../../helper"

const ProfileReward = ({userBiodata}) => {

    const reward = [
        {
            label   : "Membuat SP.OPSIN",
            date    : "01 Oktober 2023",
            note    : "5/7 Correct"
        },
        {
            label   : "Membuat Analisa Sasaran",
            date    : "01 Oktober 2023",
            note    : ""
        },
        {
            label   : "Membuat Analisa Sasaran",
            date    : "01 Oktober 2023",
            note    : ""
        },
        {
            label   : "Membuat Target Operasi",
            date    : "01 Oktober 2023",
            note    : ""
        },
        {
            label   : "Membuat Rencana Penyelidikan",
            date    : "01 Oktober 2023",
            note    : ""
        },
        {
            label   : "Menyelesaikan LAOPSIN",
            date    : "01 Oktober 2023",
            note    : ""
        },
        {
            label   : "Membuat LAPINHAR",
            date    : "01 Oktober 2023",
            note    : ""
        },
    ]
    const [badge, setBadge]                         = useState([])
    const [badgeLoading, setBadgeLoading]           = useState(false)


    const { fallbackImage_ }    = Helper

    const getPersonalBadge = () => {
        setBadgeLoading(true)
        const params = {
            user_id : userBiodata?.user_id,
            period : new Date().getFullYear(),
            semester : 0,
        }

        PerformanceAPI.getPersonalBadge(params)
            .then(res => {
                setBadge(res?.results)
                setBadgeLoading(false)
            })
            .catch((err) => {
                toast.error(err)
            })
    }
    const renderComps = (dt) => {
        return (
            <div
                className   = "d-flex mb-1"
            >
                <div className="d-flex align-items-center">
                    <img 
                        onError     = {fallbackImage_} 
                        src         = {window.location.origin + dt.badge?.attachment} 
                        // size        = 'md'
                        className   = 'rounded-circle me-1'
                        style       =   {{ minWidth : '30px', width : '2vw'}}
                    />
                </div>
                <div>
                    <div
                        className   = "col-12 p-9"
                    >
                        {dt.note}
                    </div>
                    <div
                        className   = ""
                    >
                        {moment(dt.created_at).format('DD MMMM YYYY')} 
                    </div>
                </div>
            </div>
        )
    }

    useEffect(() => {
        if (userBiodata) {
            getPersonalBadge()
        }
    }, [userBiodata])
    return (
        <>
            <Card
                className="col-12 h-100 bg-white rounded m-0 text-dark"
            >
                <CardHeader
                    className="text-primary text-center"
                >
                    <div className="col-12 text-center">
                        <strong>Daftar Reward</strong>
                    </div>
                </CardHeader>
                <CardBody>
                    {
                        !badgeLoading &&
                            badge?.map((dt) => {
                                return (
                                    <>
                                        {renderComps(dt)}
                                    </>
                                )
                        })
                    }
                    {
                        !badgeLoading && badge?.length === 0 && (
                            <div className="d-flex justify-content-center h-100 align-items-center">
                                <h5>Tidak Ada Reward</h5>
                            </div>
                        )
                    }
                    {
                        badgeLoading && (
                            <div className="d-flex justify-content-center h-100 align-items-center">
                                <h5>Loading...</h5>
                            </div>
                        )
                    }
                </CardBody>
            </Card>
        </>
    )
}

export default ProfileReward