import {get, delete_, post, put}  from '../../index'

//get
const getUserManagement     = (params)   => get(`/management/employee/`, params)
const checkUsername         = (param)    => get(`/authentication/users/`, param)
const checkEmployee         = (param)    => get(`/management/employee/check-field-required/`, param)


//post
const createUserManagement  = (formData) => post('/management/employee/create', formData)
const createUserAuth        = (formData) => post('/authentication/users/', formData)
const updateUserAuth        = (formData, id) => put(`/authentication/users/${id}/`, formData)

//put
const updateUserManagement  = (formData, id) => put(`/management/employee/update/${id}`, formData)
const changePasword         = (formData, param) => put(`/authentication/users/change-password/`, formData, param)

//delete
const deleteUserManagement  = (id) => delete_(`/management/employee/delete/${id}`)
const deleteUserLogin       = (id) => delete_(`/authentication/users/${id}`)

const userManagementAPI = {
    getUserManagement,
    checkUsername,
    checkEmployee,

    createUserManagement,
    createUserAuth,
    updateUserAuth,

    updateUserManagement,
    changePasword,

    deleteUserManagement,
    deleteUserLogin
}

export default userManagementAPI