import { url } from "../interceptor/axiosInstance"

const MAPBOX_SKIN  = "mapbox://styles/zephyrfn/ckra22zgd4uh517m5plawan4p"
const MAPBOX_TOKEN = "pk.eyJ1IjoiemVwaHlyZm4iLCJhIjoiY2trNzI5bWN1MDlubDJ1cW94Z3hicm9qdCJ9.HPNjaaL1I5rkMdSg1AJf5g"

const encodeUrl = (url) => {
    return encodeURIComponent(url)
}

const getURLMAP = (dataMap) => {
    const urlMap = MAPBOX_SKIN.replace(
        "mapbox://",
        "https://api.mapbox.com/"
    ).replace("styles/", "styles/v1/")

      let pinMap = ""
  
      dataMap?.map((data, index) => {
        if (index < 40) {
          const urlPin = `url-${encodeUrl(`${url.replace("/api", "")}/sectors/sector_id_${data.sector_id}.png`)}(${data.coordinate[0][1]},${data.coordinate[0][0]})`
          // const urlPin = `pin-l+ff0000(${data.coordinate[0][1]},${data.coordinate[0][0]})`
          if (!index) {
              pinMap += urlPin
          } else {
              pinMap += `,${urlPin}`
          }
        }
      })
  
      if (pinMap !== "") {
        return `${urlMap}/static/${pinMap}/[93.8885,-12.85,141.257,7.8559]/800x400?access_token=${MAPBOX_TOKEN}&attribution=false&logo=false`
      } else {
        return `${urlMap}/static/[93.8885,-12.85,141.257,7.8559]/800x400?access_token=${MAPBOX_TOKEN}&attribution=false&logo=false`
      }
}

export default getURLMAP