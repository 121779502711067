import moment                   from 'moment'
import Helper                   from '../../../helper'
import pdfMake                  from 'pdfmake/build/pdfmake'
import bookman                  from '../../../assets/fonts/bookman'
import pdfFonts                 from 'pdfmake/build/vfs_fonts'
import bookmanBold              from '../../../assets/fonts/bookmanBold'
import HtmlConverter            from "../../../helper/html-converter"
import bookmanItalics           from '../../../assets/fonts/bookmanItalics'
import bookmanBoldItalics       from '../../../assets/fonts/bookmanBoldItalics'
import base64Code from './kejaksaanlogo'

    const fonts_ = {...pdfFonts.pdfMake.vfs, ...bookman, ...bookmanBold, ...bookmanItalics, ...bookmanBoldItalics }
    const {reformatDate,  dateIndo} = Helper
    pdfMake.vfs = fonts_
    pdfMake.fonts = {
        bookman:{
            normal:"bookman.ttf",
            bold:"bookmanBold.ttf",
            italics:"bookmanItalics.ttf",
            bolditalics:"bookmanBoldItalics.ttf"
        }
    }

    const headerSection = (data, typeReport, copies, bold) => {
        const userData = JSON.parse(localStorage.getItem('userData'))

        const headerContent = [
            {
                alignment: 'left',
                columns: [
                    {
                        text: data?.satuan_kerja ? `${data?.created_by?.work_unit?.work_unit_level?.name} ${data?.created_by?.work_unit?.name}` : `${userData?.work_unit?.work_unit_level?.name} ${userData?.work_unit?.name}`,
                        alignment:"left",
                        decoration: 'underline',
                        width: 250,
                        bold : bold
                    },
                    {
                        text: `${typeReport}`,
                        alignment:"right",
                        width: '*'
                    }
                ]
            }
        ]

            headerContent.push(
                {
                    alignment: 'right',
                    columns: [
                        {
                            text: `\nCOPY KE : ${copies ? copies : 0}`,
                            alignment:"right",
                        },
        
                    ]
                },
                {
                    alignment: 'right',
                    columns: [
                        {
                            text: `DARI ${copies ? copies : 0} COPIES`,
                            alignment:"right",
                        },
        
                    ]
                },
            )

        return headerContent 
    }

    const headerSectionType2 = (copies, satuan_kerja) => {

        const headerContent = [
            {
                layout      : "noBorders",
                lineHeight  : 0.9,
                table       : {
                    widths  : [100, "*", 100],
                    body    : [
                        [
                            "",
                            "",
                            {
                                text: `COPY KE : ${copies ? copies : 0}`,
                                alignment:"right",
                            },
                        ],
                        [
                            "",
                            "",
                            {
                                text: `DARI ${copies ? copies : 0} COPIES`,
                                alignment:"right",
                            },
                        ],
                    ],
                },
                margin      : [0, 0, 0, 15]
            },

            {
                alignment: 'left',
                columns: [
                    {
                        width : 50,
                        height : 50,
                        image : base64Code.value
                    },
                    {
                        layout      : "noBorders",
                        lineHeight  : 0.9,
                        table       : {
                            widths  : ["*"],
                            body    : [
                                [
                                    {
                                        text: " ",
                                        alignment:"center",
                                        width: 250,
                                        bold : true,
                                        fontSize : 5,
                                    }
                                ],
                                [
                                    {
                                        text: "KEJAKSAAN REPUBLIK INDONESIA",
                                        alignment:"center",
                                        width: 250,
                                        bold : true,
                                        fontSize : 11,
                                    }
                                ],
                                [
                                    {
                                        text: satuan_kerja ? satuan_kerja : "-",
                                        alignment:"center",
                                        width: 250,
                                        bold : true,
                                        fontSize : 14,
                                    }
                                ]
                            ],
                        }
                    },
                    {
                        width : 50,
                        text : ""
                    },
                ]
            },
            
            
            {
                text : "__________________________________________________________________________________",
                alignment:"center",
            }
        ]

        return headerContent 
    }

    const headerSectionType3 = (copies, satuan_kerja) => {

        const headerContent = [
            {
                layout      : "noBorders",
                lineHeight  : 0.9,
                table       : {
                    widths  : [100, "*", 100],
                    body    : [
                        [
                            "",
                            "",
                            {
                                text: `COPY KE : ${copies ? copies : 0}`,
                                alignment:"right",
                            },
                        ],
                        [
                            "",
                            "",
                            {
                                text: `DARI ${copies ? copies : 0} COPIES`,
                                alignment:"right",
                            },
                        ],
                    ],
                }
            },
            {
                text: " ",
                alignment:"center",
                width: 250,
                bold : true,
                fontSize : 10,
            },
            {
                layout: "noBorders",
                margin: [15, 0, 15, 0],

                table: {
                    widths: [60, "*"], 
                    body: [
                        [
                            {
                                image: base64Code.value, 
                                width: 50,
                                height: 50,
                            }, 
                            {
                                stack: [
                                    {
                                        text: "KEJAKSAAN REPUBLIK INDONESIA",
                                        alignment: "center",
                                        fontSize: 10,
                                        margin: [0, 15, 0, 0],
                                    },
                                    {
                                        text: satuan_kerja ? satuan_kerja : "-",
                                        alignment: "center",
                                        fontSize: 14,
                                    },
                                ],
                            }, // Teks di kolom kanan
                        ],
                    ],
                },
            },
            // {
            //     text: satuan_kerja ? satuan_kerja : "-",
            //     alignment:"center",
            //     width: 250,
            //     // bold : true,
            //     fontSize : 14,
            // },
            {
                text : "______________________________________________________________________________",
                alignment:"center",
            }
        ]

        return headerContent 
    }

    const headerSectionType4 = (data, typeReport, copies) => {

        const headerContent = [
            {
                alignment: 'left',
                columns: [
                    {
                        text: "",
                        alignment:"left",
                        decoration: 'underline',
                        width: 250,
                    },
                    {
                        text: `${typeReport}`,
                        alignment:"right",
                        width: '*'
                    }
                ]
            }
        ]

        if (copies) {
            headerContent.push(
                {
                    alignment: 'right',
                    columns: [
                        {
                            text: `\nCOPY KE : ${copies ? copies : 0}`,
                            alignment:"right",
                        },
        
                    ]
                },
                {
                    alignment: 'right',
                    columns: [
                        {
                            text: `DARI ${copies ? copies : 0} COPIES`,
                            alignment:"right",
                        },
        
                    ]
                },
            )
        }

        return headerContent 
    }

    const headerSectionWithoutKejaksaan = (data, idReport, typeReport, copies) => {

        const userData = JSON.parse(localStorage.getItem('userData'))

        const header = [
            {
                text: `${typeReport}`,
                alignment:"right",
            },
            {
                alignment: 'right',
                columns: [
                    {
                        text: `\nCOPY KE :  ${copies ? copies : 0}`,
                        alignment:"right",
                    },
    
                ]
            },
            {
                alignment: 'right',
                columns: [
                    {
                        text: `DARI ${copies ? copies : 0} COPIES`,
                        alignment:"right",
                    },
    
                ]
            },

            // IN-9 tidak pakai di command sementara
            {
                lineHeight  : 0.9,
                table       : {
                    widths  : ["*"],
                    body    : [
                        [
                            {
                                text        : data?.satuan_kerja ? data?.satuan_kerja : `${userData?.work_unit?.work_unit_level?.name.toLowerCase() !== "kejaksaan agung" ? userData?.work_unit?.work_unit_level?.name : ""} ${userData?.work_unit?.name}`,
                                alignment   : "center",
                                bold        : true,
                                lineHeight  : 1.5,
                                border      : [false, false, false, true]
                            }
                        ]
                    ],
                },
                margin      : [0, 10, 0, 20],
            },

        ]
        return header
    }

    const headerSectionBigLetter = (data, typeReport, copies) => {
        const userData = JSON.parse(localStorage.getItem('userData'))

        const headerContent = [
        {
            lineHeight  : 0.9,
            table       : {
                widths  : ["*"],
                body    : [
                    [
                        {
                            text        : data?.satuan_kerja ? data?.satuan_kerja : `${userData?.work_unit?.work_unit_level?.name} ${userData?.work_unit?.name}`,
                            alignment   : "center",
                            fontSize    : 22,
                            lineHeight  : 1.5,
                            border      : [false, false, false, true]
                        }
                    ]
                ],
            },
            margin      : [0, 0, 0, 20],
        },
        {
            alignment: 'right',
            columns: [
                {
                    text: `${typeReport?.report}`,
                    alignment:"right",
                }
            ]
        },
        {
            alignment: 'right',
            columns: [
                {
                    text: `\nCOPY KE : ${copies ? copies : 0}`,
                    alignment:"right",
                },

            ]
        },
        {
            alignment: 'right',
            margin      : [0, 0, 0, 15],
            columns: [
                {
                    text: `DARI ${copies ? copies : 0} COPIES`,
                    alignment:"right",
                },

            ]
        },
        ]
        
        return headerContent 
    }

    const subHeaderDefault = (data, title, noMargin, noBold, underline, listDotted, numberBold) => {

        const subHeaderSec = [
            {
                alignment:"center",
                bold: !noBold,
                columns: [
                    {
                        text: title?.toUpperCase(),
                        margin: [0, 20, 0, noMargin ? 5 : 0],
                        decoration: underline ? 'underline' : "",
                    }
                ]
            },
           
        ]

        if (listDotted) {
            subHeaderSec.push({
                alignment:"center",
                columns: [
                    {
                        text:`-------------------------------------------------------------------------------`,
                    }
                ]
            },)
        }

        if ("nomor_surat" in data || "nomor" in data) {
            subHeaderSec.push({
                alignment:"center",
                bold: numberBold,

                columns: [
                    {
                        text:`NOMOR : ${data?.nomor_surat ? data?.nomor_surat : data?.nomor}`,
                        margin: [0, 0, 0, 10],
                    }
                ]
            })
        }


        return subHeaderSec
    }

    const subHeaderDefaultNoNumber = (data, title, noMargin, line, bold) => {
        console.log(line)
        const subHeaderSec = [
            {
                alignment:"center",
                bold:bold,
                columns: [
                    {
                        text: title?.toUpperCase(),
                        decoration: line ? "underline" : '',
                        margin: [0, 20, 0, noMargin ? 5 : 20],
                    }
                ]
            }
        ]
        return subHeaderSec
    }

    const subHeaderWithLetterNumber = (data, title) => {
        const subHeaderSec = [
            {
                alignment:"center",
                bold:false,
                columns: [
                    {
                        text: title?.toUpperCase(),
                        margin: [0, 10, 0, 0],

                    }
                ]
            },
            {
                alignment:"center",
                columns: [
                    {
                        text:`-------------------------------------------------------------------------------`,
                    }
                ]
            },
            {
                alignment:"center",
                columns: [
                    {
                        text:`NOMOR : ${data?.nomor_surat ? data?.nomor_surat : data?.nomor_dokumen ? data?.nomor_dokumen : data?.nomor ? data?.nomor : "" }`,
                        margin: [0, 0, 0, 10],
                    }
                ]
            },
        ]
        return subHeaderSec
    }

    const subHeaderText = (data, alignment) => {
        const subHeaderSec = []

        data?.map((dt) => {
            if (typeof (dt) === "string") {
                subHeaderSec.push(
                    {
                        alignment   : alignment,
                        columns     : [
                            {
                                text: dt,
                                margin: [0, 0, 0, 5],
                            }
                        ]
                    }
                )
            } else {
                const property = {
                    alignment   : alignment,
                    columns     : [
                        {
                            text: dt,
                            margin: [0, 0, 0, 5],
                        }
                    ]
                }

                if (dt.bold) {
                    property.bold = true
                }

                subHeaderSec.push(property)
            }
        })

        subHeaderSec.push({
            alignment   : "center",
            columns     : [
                {
                    text: "",
                    margin: [0, 0, 0, 5],
                }
            ]
        })
        return subHeaderSec
    }

    const subHeaderIN20 = (data) => {

        let hari, tanggal

        if (typeof data?.tanggal === "string") {

            hari    = moment.unix(data?.tanggal).locale('id').format("dddd")
            tanggal = moment.unix(data?.tanggal).format('DD MMMM YYYY')
        } else {
            if (Array.isArray(data?.tanggal)) {
                hari    = moment(data?.tanggal[0]).locale('id').format('dddd')
                tanggal = moment(data?.tanggal[0]).format('DD MMMM YYYY')
            } else {
                hari    = moment(data?.tanggal).locale('id').format('dddd')
                tanggal = moment(data?.tanggal).format('DD MMMM YYYY')
            }
        }


        const subHeader = [
            {
                alignment:"center",
                bold:true,
                columns: [
                    {
                        text: 'ANALISA BERITA',
                        bold: true,
                        size: 24,
                        decoration:'underline',
                        margin: [0, 20, 0, 0]
                    }
                ]
            },
            {
                alignment:"center",
                columns: [
                    {
                        text: `${hari}, ${tanggal}`,
                        bold: true,
                        margin: [0, 0, 0, 30]
                    }
                ]
            },
            {
                alignment:"center",
                columns: [
                    {
                        text: `JUDUL BERITA`,
                    }
                ]
            },
            {
                alignment:"center",
                columns: [
                    {
                        text: `${data?.judul_berita ? data?.judul_berita : '-'}`,
                        margin: [0, 0, 0, 10]
                    }
                ]
            },
        ]

        return subHeader
    }

    const subHeaderLampiran = (data, tanggal, kepada, lokasi, bold) => {
        let tanggalSurat = null

        if (typeof tanggal === "string") {
             tanggalSurat = moment.unix(tanggal).format("DD MMMM YYYY")
        } else {
            if (Array.isArray(tanggal)) {
                tanggalSurat = moment(tanggal[0]).locale('id').format('DD MMMM YYYY')
            } else {
                tanggalSurat = moment(tanggal).locale('id').format('DD MMMM YYYY')
            }
        }


        const subHeader = [
            {
                layout      : "noBorders", 
                lineHeight  : 0.9,
                table       : {
                    widths  : [250, 20,  '*'],
                    body    : [
                        [
                            {
                                layout  : "noBorders",
                                table   : {
                                    widths: [60, 5, '*'],
                                    body:[
                                        ["Nomor",      ":", {text: data?.nomor_surat ? data?.nomor_surat : "-",                                 alignment:"left"}],
                                        ["Sifat",      ":", {text: data?.sifat_surat ? Helper.capitalizeFirstLetter(data?.sifat_surat) : "-",   alignment:"left"}],
                                        ["Lampiran",   ":", {text: data?.lampiran ? data?.lampiran : "-",                                       alignment:"left"}],
                                        ["Perihal", ":", {text: data?.perihal ? data?.perihal : "-",   alignment:"left"}]
                                    ],
                                }
                            }, 
                            "",
                            {
                                layout  : "noBorders",
                                table   : {
                                    widths: [180],
                                    body:[
                                        [{text:`${data?.dikeluarkan_di ? data?.dikeluarkan_di : "Jakarta"}, ${tanggalSurat}`, alignment:"left"}],
                                        [{text : " "}],
                                        [{text : " "}],
                                        [{text:"Kepada Yth :", bold: bold}],
                                        [{text: kepada ? kepada : "-", alignment:"left", bold: bold}],
                                        ["Di-"],
                                        [{text: lokasi ? lokasi : "-", alignment:"left", decoration: 'underline'}],
                                    ],
                                }
                            }
                        ]
                        // ["Nomor",      ":", {text: data?.nomor_surat ? data?.nomor_surat : "-",                                alignment:"left"}, "", "Kepada Yth :"],
                        // ["Sifat",      ":", {text: data?.sifat_surat ? Helper.capitalizeFirstLetter(data?.sifat_surat) : "-",  alignment:"left"}, "", {text: kepada ? kepada : "-", alignment:"left"}],
                        // ["Lampiran",   ":", {text: data?.lampiran ? data?.lampiran : "-",                                      alignment:"left"}, "", "di-"],
                        // [
                        //     "Perihal", ":", {text: data?.perihal ? data?.perihal : "-",  alignment:"left"}, "", {text: lokasi ? lokasi : "-", alignment:"left", decoration: 'underline'} 
                            // {
                            //     layout  : "noBorders",
                            //     table   : {
                            //         widths: ['*'],
                            //         body:[
                            //             ["Kepada Yth :"],
                            //             [{text: kepada ? kepada : "-", alignment:"left"}],
                            //             ["di-"],
                            //             [{text: lokasi ? lokasi : "-", alignment:"left"}],
                            //         ],
                            //     }
                            // }
                        // ],
                    ],
                }
            }
        ]
        return subHeader
    }
    const removeTags = (string) =>  {
        return string?.replace(/<[^>]*>/g, ' ')
                .replace(/\s{2,}/g, ' ')
                .trim()
    }

    const content = (data) => {

        const dasar         = []
        const untuk         = []
        const kepada        = []
        const pertimbangan  = []

        if (Array.isArray(data?.pertimbangan)) {
            if (data?.pertimbangan?.length > 0) {
                data?.pertimbangan?.map((val) => {
                    pertimbangan.push(HtmlConverter.convertToPdf(val))

                    // const obj_ = {
                    //     width       : "*",
                    //     alignment   : "justify"
                    // }

                    // if (Helper.isNestedArray(HtmlConverter.convertToPdf(val))) {
                    //     pertimbangan.push({...obj_, ...HtmlConverter.convertToPdf(val)[0][0]})
                    // } else {
                    //     pertimbangan.push({...obj_, ...HtmlConverter.convertToPdf(val)[0]})
                    // }
                })
            } else {
                pertimbangan.push({
                    text        : "",
                    width       : "*",
                    alignment   : "justify"
                })
            }
        } else {
            const obj_ = {
                width       : "*",
                alignment   : "justify"
            }
            if (data?.pertimbangan?.length > 0) {
                pertimbangan.push(HtmlConverter.convertToPdf(data?.pertimbangan))

                // pertimbangan.push({...obj_, ...HtmlConverter.convertToPdf(data?.pertimbangan)[0][0]})
            } else {
                obj_.text = ""
                pertimbangan.push({...obj_})
            }
        }

        if (Array.isArray(data?.dasar)) {

            if (data?.dasar?.length > 0) {
                data?.dasar.map((val) => {
                    dasar.push(HtmlConverter.convertToPdf(val))

                    // const obj_ = {
                    //     width       : "*",
                    //     alignment   : "justify"
                    // }
    
                    // const newObj_ = {...obj_, ...HtmlConverter.convertToPdf(val)[0]}
                })
            } else {
                dasar.push({
                    text        : "",
                    width       : "*",
                    alignment   : "justify"
                })
            }
        } else {
            const obj_ = {
                width       : "*",
                alignment   : "justify"
            }

            if (data?.dasar?.length > 0) {
                dasar.push(HtmlConverter.convertToPdf(data?.dasar))

                // dasar.push({...obj_, ...HtmlConverter.convertToPdf(data?.dasar)[0][0]})
            } else {
                obj_.text = ""
                dasar.push({...obj_})
            }
        }

        if (Array.isArray(data?.memerintahkan)) {
            if (data?.memerintahkan.length > 0) {
                data?.memerintahkan?.forEach((val, idx) => {
                    kepada.push(
                        [`${idx + 1}.`, "Nama", ":", val ? val?.name : ""],
                        ["", "Pangkat", ":", val?.grade ? val?.grade?.name : ""],
                        ["", "NIP", ":", val ? val?.nip : ""],
                        ["", "Jabatan", ":", val.position ? val?.position?.name : ""]
                    )
                })
            } else {
                kepada.push(
                    [`1.`, "Nama", ":", ""],
                    ["", "Pangkat", ":", ""],
                    ["", "NIP", ":",   ""],
                    ["", "Jabatan", ":", ""]
                )
            }

        } else {
            kepada.push(
                [`1.`, "Nama", ":", data?.memerintahkan ? data?.memerintahkan?.name : ""],
                ["", "Pangkat", ":",  data?.memerintahkan?.grade ? data?.memerintahkan?.grade?.name : ""],
                ["", "NIP", ":",  data?.memerintahkan ? data?.memerintahkan?.nip : ""],
                ["", "Jabatan", ":",  data?.memerintahkan?.position ? data?.memerintahkan?.position?.name : ""]
            )
        }

        if (Array.isArray(data?.peruntukan)) {

            if (data?.peruntukan.length > 0) {
                data?.peruntukan.map((val) => {
                    untuk.push(HtmlConverter.convertToPdf(val))

                    // const obj_ = {
                    //     width       : "*",
                    //     alignment   : "justify"
                    // }
    
                    // const newObj_ = {...obj_, ...HtmlConverter.convertToPdf(val)[0][0]}
                    // untuk.push(newObj_)
                })
            } else {
                untuk.push({
                    text        : "",
                    width       : "*",
                    alignment   : "justify"
                })
            }

        } else {
            const obj_ = {
                text        : "",
                width       : "*",
                alignment   : "justify"
            }

            if (data?.peruntukan?.length) {
                untuk.push(HtmlConverter.convertToPdf(data?.peruntukan))

                // untuk.push({...obj_, ...HtmlConverter.convertToPdf(data?.peruntukan)[0][0]})
            } else {
                obj_.text = ""
                untuk.push({...obj_})
            }
        }
        
        const contentSec = [
            {
                columns: [
                    
                    {
                        width: 98,
                        text: 'Menimbang',
                        margin: [0, 10, 0, 0],
                    },
                    {
                        width: 20,
                        text: ':',
                        margin: [0, 10, 0, 0],
                    },
                    {
                        ol:[...pertimbangan],
                        margin: [0, 10, 0, 0],
                    },
                ]
            },
            {
                columns: [        
                    {
                        width: 98,
                        text: 'Dasar',
                        margin: [0, 10, 0, 0],
                    },
                    {
                        width: 20,
                        text: ':',
                        margin: [0, 10, 0, 0],
                    },
                    {
                        ol:[...dasar],
                        margin: [0, 10, 0, 0],
                    },
                ]
            },
            {
                alignment:"center",
                bold:true,
                columns:[
                    {
                        text:"MEMERINTAHKAN",
                        margin:[0, 10]
                    }
                ]
            },
            {
                font:"bookman",
                columns: [
                    
                    {
                        width: 98,
                        text: 'Kepada',
                        margin:[0, 0],
                        headlineLevel:1,
                    },
                    {
                        width: 20,
                        text: ':',
                        headlineLevel:1,
                    },
                   {
                        layout:"noBorders",
                        lineHeight:1,
                        table:{
                            body:kepada,
                        },
                   }
                ]
            },
            {
                columns: [
                    
                    {
                        width: 98,
                        text: 'Untuk',
                        margin: [0, 10, 0, 0],
                    },
                    {
                        width: 20,
                        text: ':',
                        margin: [0, 10, 0, 0],
                    },
                    {
                        ol:[...untuk],
                        margin: [0, 10, 0, 0],
                    },
                ]
            },
        ]
        return contentSec
    }

    const signDefault = (tanggal, dikeluarkan, posisi, name, grade, nip, classes) => {

        let tanggalSurat = null

        if (typeof tanggal === "string") {
             tanggalSurat = moment.unix(tanggal).format("DD MMMM YYYY")
        } else {
            if (Array.isArray(tanggal)) {
                tanggalSurat = moment(tanggal[0]).locale('id').format('DD MMMM YYYY')
            } else {
                tanggalSurat = moment(tanggal).locale('id').format('DD MMMM YYYY')
            }
        }

        const signSec = [
            {
                layout:"noBorders",
                margin:[0, 10, 0, 0],
                id:"signature",
                lineHeight:0.7,
                table:{
                    widths:[170, "*", 10, "*"],
                    heights:["auto", 20, 50],
                    body:[
                        ["", "Dikeluarkan di", ":", dikeluarkan ? dikeluarkan : ""],
                        ["", "Pada Tanggal", ":", tanggalSurat], 
                    ],
                },
                
            },
            {
                columns   : [
                    {
                        width : 180,
                        text  : "",
                        alignment: 'center'
                    },
                    {
                        width : "*",
                        text  : posisi,
                        alignment: 'center',
                    }
                ]
            },
            {
                text        : " ",
                alignment   : 'center',
                lineHeight  : 4,
            },
            {
                columns   : [
                    {
                        width : 180,
                        text  : "",
                        alignment: 'center'
                    },
                    {
                        width : "*",
                        text  : name,
                        alignment: 'center',
                        fontSize: '12',
                        decoration: 'underline'
                    }
                ]
            }
        ]
        return signSec
    }

    const signDateTop = (tanggal, dikeluarkan, jabatan, petugas, grade, nip, classes) => {
        
        let tanggalSurat

        if (typeof tanggal === "string") {
            if (isNaN(parseInt(tanggal))) {
                tanggalSurat = moment(tanggal).format("DD MMMM YYYY")
            } else {
   
                tanggalSurat = moment.unix(tanggal).format("DD MMMM YYYY")
            }
        } else {
            if (Array.isArray(tanggal)) {
                tanggalSurat = moment(tanggal[0]).locale('id').format('DD MMMM YYYY')
            } else {
                tanggalSurat = moment(tanggal).locale('id').format('DD MMMM YYYY')
            }
        }
        
        const sign = [
            {
                margin:[0, 30, 0, 0], 
                layout:"noBorders", 
                id:"signature", 
                lineHeight:1, 
                table:{
                    widths:["*", 280], 
                    heights: [10, 70], 
                    body:[
                        ["", {text:`${dikeluarkan}, ${tanggalSurat !== "Invalid date" ? tanggalSurat : moment(tanggal).format("DD MMMM YYYY") !== "Invalid date" ? moment(tanggal).format("DD MMMM YYYY") : tanggal }`, alignment:"center"}], 
                        ["", {text: jabatan,  alignment:"center"}], 
                        ["", {text:"",  alignment:"center"}], 
                        ["", {text: petugas ? petugas : "",  alignment:"center",  decoration:"underline",  lineHeight:1}], 
                        ["", {text: `${grade ? grade : ""} / ${classes ? classes : ""}`,  alignment:"center"}], 
                        ["", {text: `NIP. ${nip ? nip : ""}`,  alignment:"center"}], 
    
                    ],
                }
            }
        ]
        return sign
        
    }

    const signDateTopLIN1 = (tanggal, dikeluarkan, jabatan, petugas, grade, nip, classes) => {
        
        let tanggalSurat

        if (typeof tanggal === "string") {
            if (isNaN(parseInt(tanggal))) {
                tanggalSurat = moment(tanggal).format("DD MMMM YYYY")
            } else {
   
                tanggalSurat = moment.unix(tanggal).format("DD MMMM YYYY")
            }
        } else {
            if (Array.isArray(tanggal)) {
                tanggalSurat = moment(tanggal[0]).locale('id').format('DD MMMM YYYY')
            } else {
                tanggalSurat = moment(tanggal).locale('id').format('DD MMMM YYYY')
            }
        }
        
        const sign = [
            {
                margin:[0, 30, 0, 0], 
                layout:"noBorders", 
                id:"signature", 
                lineHeight:1, 
                table:{
                    widths:["*", 280], 
                    heights: [10, 10, 70], 
                    body:[
                        ["", {text:`${dikeluarkan}, ${tanggalSurat !== "Invalid date" ? tanggalSurat : moment(tanggal).format("DD MMMM YYYY") !== "Invalid date" ? moment(tanggal).format("DD MMMM YYYY") : tanggal }`, alignment:"center"}], 
                        ["", {text:"Yang Membuat Laporan",  alignment:"center"}], 
                        ["", {text: jabatan,  alignment:"center"}], 
                        ["", {text:"",  alignment:"center"}], 
                        ["", {text: petugas ? petugas : "",  alignment:"center",  decoration:"underline",  lineHeight:1}], 
                        ["", {text: `${grade ? grade : ""} / ${classes ? classes : ""}`,  alignment:"center"}], 
                        ["", {text: `NIP. ${nip ? nip : ""}`,  alignment:"center"}], 
    
                    ],
                }
            }
        ]
        return sign
        
    }

    const signDateTopIN20 = (tanggal, dikeluarkan, jabatan, petugas, grade, nip, classes) => {
        
        let tanggalSurat

        if (typeof tanggal === "string") {
            if (isNaN(parseInt(tanggal))) {
                tanggalSurat = moment(tanggal).format("DD MMMM YYYY")
            } else {
   
                tanggalSurat = moment.unix(tanggal).format("DD MMMM YYYY")
            }
        } else {
            if (Array.isArray(tanggal)) {
                tanggalSurat = moment(tanggal[0]).locale('id').format('DD MMMM YYYY')
            } else {
                tanggalSurat = moment(tanggal).locale('id').format('DD MMMM YYYY')
            }
        }
        
        const sign = [
            {
                margin:[0, 30, 0, 0], 
                layout:"noBorders", 
                id:"signature", 
                lineHeight:1, 
                table:{
                    widths:["*", 280], 
                    heights: [10, 10, 70], 
                    body:[
                        ["", {text:`${dikeluarkan}, ${tanggalSurat !== "Invalid date" ? tanggalSurat : moment(tanggal).format("DD MMMM YYYY") !== "Invalid date" ? moment(tanggal).format("DD MMMM YYYY") : tanggal }`, alignment:"center"}], 
                        ["", {text:`Yang menerangkan`, alignment:"center"}], 
                        ["", {text: jabatan,  alignment:"center"}], 
                        ["", {text:"",  alignment:"center"}], 
                        
                        ["", {text: petugas ? petugas : "",  alignment:"center",  decoration:"underline",  lineHeight:1}], 
                        ["", {text: `${grade ? grade : ""} / ${classes ? classes : ""}`,  alignment:"center"}], 
                        ["", {text: `NIP. ${nip ? nip : ""}`,  alignment:"center"}], 
    
                    ],
                }
            }
        ]
        return sign
        
    }

    const signDateTopNoPositionNip = (tanggal, dikeluarkan, jabatan, petugas, grade, nip, classes) => {
        
        let tanggalSurat

        if (typeof tanggal === "string") {
            if (isNaN(parseInt(tanggal))) {
                tanggalSurat = moment(tanggal).format("DD MMMM YYYY")
            } else {
   
                tanggalSurat = moment.unix(tanggal).format("DD MMMM YYYY")
            }
        } else {
            if (Array.isArray(tanggal)) {
                tanggalSurat = moment(tanggal[0]).locale('id').format('DD MMMM YYYY')
            } else {
                tanggalSurat = moment(tanggal).locale('id').format('DD MMMM YYYY')
            }
        }

        console.log(tanggal, tanggalSurat, 'tanggal dikeluarkan')
        
        const sign = [
            {
                margin:[0, 30, 0, 0], 
                layout:"noBorders", 
                id:"signature", 
                lineHeight:1, 
                table:{
                    widths:["*", 250], 
                    heights: [10, 70], 
                    body:[
                        ["", {text:`${dikeluarkan}, ${tanggalSurat !== "Invalid date" ? tanggalSurat : moment(tanggal).format("DD MMMM YYYY") !== "Invalid date" ? moment(tanggal).format("DD MMMM YYYY") : tanggal }`, alignment:"center"}], 
                        ["", {text: jabatan,  alignment:"center"}], 
                        ["", {text:"",  alignment:"center"}], 
                        ["", {text: petugas ? petugas : "",  alignment:"center",  decoration:"underline",  lineHeight:1}], 
                    ],
                }
            }
        ]
        return sign
        
    }

    const signJabatanTop = (position, name, grade, nomor, nip) => {
        const sign = [
            // {
            //     margin:[0, 30, 0, 0], 
            //     layout:"noBorders", 
            //     id:"signature", 
            //     lineHeight:0.7, 
            //     table:{
            //         widths:["*", 250], 
            //         heights: [50], 
            //         body:[
            //             ["", {text:position,  alignment:"center"}], 
            //             ["", {text:"",  alignment:"center"}], 
            //             ["", {text:name,  alignment:"center", lineHeight:1, decoration:"underline"}], 
            //             ["", {text:`${grade} ${classes}`,  alignment:"center"}], 
            //             ["", {text:`NIP. ${nip}`, alignment:"center"}], 
    
            //         ],
            //     }
            // },
            {
                columns   : [
                    {
                        width : 180,
                        text  : "",
                        alignment: 'center'
                    },
                    {
                        width : "*",
                        text  : position,
                        alignment: 'center',
                    }
                ]
            },
            {
                text        : " ",
                alignment   : 'center',
                lineHeight  : 4,
            },
            {
                columns   : [
                    {
                        width : 180,
                        text  : "",
                        alignment: 'center'
                    },
                    {
                        width : "*",
                        text  : name,
                        alignment: 'center',
                        fontSize: '12',
                        decoration: 'underline'
                    }
                ]
            },
            {
                columns   : [
                    {
                        width : 180,
                        text  : "",
                        alignment: 'center'
                    },
                    {
                        width : "*",
                        text  : `${grade}/${nomor}`,
                        alignment: 'center',
                        fontSize: '12',
                    }
                ]
            },
            {
                columns   : [
                    {
                        width : 180,
                        text  : "",
                        alignment: 'center'
                    },
                    {
                        width : "*",
                        text  : `Nip : ${nip}`,
                        alignment: 'center',
                        fontSize: '12',
                    }
                ]
            },

        ]
        
        return sign
        
    }

    const signWithNipPosition = (position, name, grade, nip) => {
        const sign = [
            {
                columns   : [
                    {
                        width : 180,
                        text  : "",
                        alignment: 'center'
                    },
                    {
                        width : "*",
                        text  : position,
                        alignment: 'center',
                    }
                ]
            },
            {
                text        : " ",
                alignment   : 'center',
                lineHeight  : 4,
            },
            {
                columns   : [
                    {
                        width : 180,
                        text  : "",
                        alignment: 'center'
                    },
                    {
                        width : "*",
                        text  : name,
                        alignment: 'center',
                        fontSize: '12',
                        decoration: 'underline'
                    }
                ]
            },
            {
                columns   : [
                    {
                        width : 180,
                        text  : "",
                        alignment: 'center'
                    },
                    {
                        width : "*",
                        text  : `${grade}/NIP.${nip}`,
                        alignment: 'center',
                        fontSize: '12',
                    }
                ]
            }
        ]
        return sign
        
    }

    const signAutentikasi = (tanggal, penandatangan_autentikasi, penandatangan, report) => {

        let tanggalSurat

        if (typeof tanggal === "string") {
            
            if (isNaN(parseInt(tanggal))) {
                tanggalSurat = moment(tanggal).format("DD MMMM YYYY")
            } else {
   
                tanggalSurat = moment.unix(tanggal).format("DD MMMM YYYY")
            }
        } else {
            if (Array.isArray(tanggal)) {
                tanggalSurat = moment(tanggal[0]).format('DD MMMM YYYY')
            } else {
                tanggalSurat = moment(tanggal).format('DD MMMM YYYY')
            }
        }

        const sign = [
            {
                margin:[0, 30, 0, 0], 
                layout:"noBorders", 
                id:"signature", 
                table:{
                    widths:[225, 225], 
                    body:[
                        [
                            {
                                layout:"noBorders", 
                                lineHeight:1, 
                                table:{
                                    widths:[200], 
                                    heights:[5, "auto", 90], 
                                    body:[
                                        [{text: ` `,  alignment:"center"}], 
                                        [{text: "Autentikasi",  alignment:"center"}], 
                                        [{text: `${penandatangan_autentikasi?.position?.name ? penandatangan_autentikasi?.position?.name : "-"}`, alignment:"center"}], 
                                        [{text: `${penandatangan_autentikasi?.name ? penandatangan_autentikasi?.name : "-"}`,  alignment:"center",  lineHeight:1, decoration:"underline"}], 
                                        [{text: `${penandatangan_autentikasi?.grade?.name ? penandatangan_autentikasi?.grade?.name : "-"} / Nip. ${penandatangan_autentikasi?.nip}`,  alignment:"center"}], 
                                    ]
                                }
                            }, 
                            {
                                layout:"noBorders", 
                                lineHeight:1, 
                                table:{
                                    widths:[250], 
                                    heights:[5, "auto", 90], 
                                    body:[
                                        [{text: report !== '32' ? `Jakarta,  ${tanggalSurat}` : " ",  alignment:"center"}], 
                                        [
                                            {
                                                text: report === '29' || report === '31' ? 
                                                        "Pejabat Yang Membuat Laporan" 
                                                        :  report === '30' ? 
                                                            "Pembuat Telaahan Intelijen"
                                                        :   report === '32' ? 
                                                            `Jakarta,  ${tanggalSurat}`
                                                        :
                                                            "Yang Membuat Laporan",  
                                                alignment:"center"
                                            }
                                        ], 
                                        [{text: `${penandatangan?.position?.name ? penandatangan?.position?.name : "-"}`, alignment:"center"}], 
                                        [{text: `${penandatangan?.name ? penandatangan?.name : "-"}`,  alignment:"center",  lineHeight:1, decoration:"underline"}], 
                                        [{text: `${penandatangan?.grade?.name ? penandatangan?.grade?.name : "-"} / Nip. ${penandatangan?.nip}`,  alignment:"center"}], 
                                    ]
                                }
                            },
                        ]
                    ]
                }
            }
        ]
        return sign
    }
    const signRecipt = (data, tanggal) => {
        let tanggalSurat = moment.unix(data?.tanggal_surat).format("DD MMMM YYYY")

        if (tanggalSurat === "Invalid date") {
            tanggalSurat = moment(new Date(parseInt(data?.tanggal_surat) * 1000), "YYYY-MM-DD").locale('id').format("DD MMMM YYYY")
        }

        const sign = [
            {
                margin      : [0, 20, 0, 0],
                layout      : "noBorders",
                table       : {
                    widths  : [225, 225], 
                    body    : [
                        [
                            {
                                layout      : "noBorders",
                                table: {
                                    widths: ["*"],
                                    body: [
                                        [{ text: " ", alignment: "center" }],
                                        [{ text: "Yang Menyampaikan", alignment: "center" }],
                                        [{ text: " ", alignment: "center" }],
                                        [{ text: " ", alignment: "center" }],
                                        [{ text: " ", alignment: "center" }],
                                        [{ text: " ", alignment: "center" }],
                                        [{ text: data?.nama_pelapor, alignment: "center", lineHeight: 1 }],
                                    ],
                                }
                            },
                            {
                                layout      : "noBorders",
                                table: {
                                    widths: ["*"],
                                    body: [
                                        [
                                            { 
                                                text        : `Jakarta, ${!isNaN(parseInt(data?.tanggal)) ? moment.unix(data?.tanggal).format("DD MMMM YYYY") : Array.isArray(data?.tanggal) ? moment(data?.tanggal[0]).format("DD MMMM YYYY") : "-"}`, 
                                                alignment   : "center" 
                                            }
                                        ],
                                        [{ text: "Yang Menerima", alignment: "center" }],
                                        [{ text: `${data.penandatangan?.position?.name}`, alignment: "center" }],
                                        [{ text: " ", alignment: "center" }],
                                        [{ text: " ", alignment: "center" }],
                                        [{ text: " ", alignment: "center" }],
                                        [{ text: data?.penandatangan?.name, alignment: "center", decoration: "underline", lineHeight: 1 }],
                                        [{ text: `${data.penandatangan?.grade?.name} ${data.penandatangan?.classes?.name}`, alignment: "center" }],
                                        [{ text: `NIP. ${data.penandatangan?.nip}`, alignment: "center" }],
                                    ],
                                }
                            }
                        ]
                    ]
                }
            },
            
        ]
        return sign
        
    }

    const signReciptMengetahuiMembuat = (data) => {
        let tanggalSurat = moment.unix(data?.tanggal_surat).format("DD MMMM YYYY")

        if (tanggalSurat === "Invalid date") {
            tanggalSurat = moment(new Date(parseInt(data?.tanggal_surat) * 1000), "YYYY-MM-DD").locale('id').format("DD MMMM YYYY")
        }

        const sign = [
            {
                margin      : [0, 20, 0, 0],
                layout      : "noBorders",
                id          : "signature", 
                table       : {
                    widths  : ["*", 50, "*"], 
                    body    : [
                        [
                            { text: " ", alignment: "center" },
                            { text: " ", alignment: "center" },
                            { text: " ", alignment: "center" }
                        ],
                        [
                            { text: "Mengetahui", alignment: "center" },
                            { text: " ", alignment: "center" },
                            { text: "Yang Membuat", alignment: "center" }
                        ],
                        [
                            { text: "", alignment: "center" },
                            { text: " ", alignment: "center" },
                            [{ text: `${data.pembuat?.position?.name ? data.pembuat?.position?.name : "-"}`, alignment: "center" }],
                        ],
                        [
                            { text: " ", alignment: "center" },
                            { text: " ", alignment: "center" },
                            { text: " ", alignment: "center" }
                        ],
                        [
                            { text: " ", alignment: "center" },
                            { text: " ", alignment: "center" },
                            { text: " ", alignment: "center" }
                        ],
                        [
                            { text: " ", alignment: "center" },
                            { text: " ", alignment: "center" },
                            { text: " ", alignment: "center" }
                        ],
                        [
                            [{ text: `${data?.mengetahui?.name ? data?.mengetahui?.name : "-"}`, alignment: "center", decoration: "underline", lineHeight: 1 }],
                            { text: " ", alignment: "center" },
                            [{ text: data?.pembuat?.name, alignment: "center", decoration: "underline", lineHeight: 1 }],
                        ],
                        [
                            { text: " ", alignment: "center" },
                            { text: " ", alignment: "center" },
                            [{ text: `${data.pembuat?.grade?.name ? data.pembuat?.grade?.name : "-"} ${data.pembuat?.classes?.name ? data.pembuat?.classes?.name : "-"}`, alignment: "center" }],
                        ],
                        [
                            { text: " ", alignment: "center" },
                            { text: " ", alignment: "center" },
                            [{ text: `NIP. ${data.pembuat?.nip ? data.pembuat?.nip : "-"}`, alignment: "center" }],
                        ]
                    ]
                }
            },
            
        ]
        return sign
        
    }

    const signPelaksanaOperasi = (data, showDateInstruction, reportName) => {

        const pelaksana = []
            data?.pelaksana_operasi?.forEach((val, i) => {
                // pelaksana.push(`${val?.name}\n${val?.grade?.name}\nNip: ${val?.nip}`)
                pelaksana.push([
                    {
                        margin:[0, 10, 0, 5],
                        text:val?.name,
                        decoration:"underline"
                    },
                    {
                        margin:[0, 0, 0, 0],
                        text: `${val?.grade?.name} / Nip : ${val?.nip}`,
                        alignment:"left"
                    },
                ])
            })

        const sign = [
            {
                layout:'noBorders',
                margin:[0, 10],
                table: {
                widths:["*", "auto"],
                body: [
                
                    [
                        "",
                        {
                            // border: [true, false, true, true],
							columns:[
                                {
                                    text:`Jakarta, ${reformatDate(data?.tanggal, "date")}\nPelaksana Operasi`,
                                    alignment:"center"
                                }
                            ]
                        }
                    ], [
                        "",
                        {
                            
                            ol:pelaksana
                        }
                    ]
                  ],
                },
                
            },
            {
                margin:[0, 10],
                table: {
                widths:[30, "*", 30],
                body: [
                    [
                        "",
                        {
                            layout:'noBorders',
                            table: {
                                id:"signature",
                                widths:["*"],
                                body: [
                                    [
                                        {
                                            border: [true, true, true, false],
                                            columns:[
                                                {
                                                    text:`Petunjuk ${data?.penandatangan?.position ? data?.penandatangan?.position?.name : "-"}`,
                                                },
                                            ]
                                        }
                                    ],
                                    [
                                        {
                                            border: [true, false, true, false],
                                            columns:[
                                                {
                                                    text:`Atas ${reportName} No. ${data?.nomor_surat}`
                                                },
                                            ]
                                        }
                                    ],
                                    [data?.petunjuk_pimpinan !== "__blank" ? HtmlConverter.convertToPdf(data?.petunjuk_pimpinan) : "-"],
                                    [{text : " ", margin : [0, 0, 0, -10]}],
                                    [
                                        {
                                            layout:'noBorders',
                                            table: {
                                            widths:[81, 5, "*"],
                                            body: [
                                                [{ text: "Dikeluarkan di", margin: [0, 0, 0, 0] }, { text: ":", margin: [0, 0, 0, 0] }, { text: "Jakarta", margin: [0, 0, 0, 0] }],
                                                ["Pada Tanggal", ":", `${showDateInstruction ? data?.tanggal_petunjuk_pimpinan ? reformatDate(data?.tanggal_petunjuk_pimpinan, "date") : "-" : reformatDate(data?.tanggal, "date")}`],

                                              ],
                                            },
                                        }
                                        // {
                                        //     border: [true, false, true, false],
                                        //     columns:[
                                        //         {
                                        //             text:`Dikeluarkan di Jakarta \nPada Tanggal : ${showDateInstruction ? data?.tanggal_petunjuk_pimpinan ? reformatDate(data?.tanggal_petunjuk_pimpinan, "date") : "-" : reformatDate(data?.tanggal, "date")}`,

                                        //         }
                                        //     ]
                                        // }
                                    ],
                                    [
                                        {
                                            layout:'noBorders',
                                            table: {
                                                id:"signature",
                                                widths:["*", "auto"],
                                                body: [
                                                    [
                                                        {text:""},
                                                        {
                                                            text:`${data?.penandatangan?.position ? data?.penandatangan?.position?.name : "-"}`,
                                                            alignment:"center",
                                                        },
                                                    ],
                                                    [
                                                        {text:""},
                                                        {
                                                            margin:[0, 20],
                                                            text : ""
                                                        }
                                                    ],
                                                    [
                                                        {text:""},
                                                        {
                                                            text:`${data?.penandatangan?.name ? data?.penandatangan?.name : "-"}`,
                                                            alignment:"center",
                                                            decoration:"underline"
                                                        },
                                                    ],
                                                    [
                                                        {text:""},
                                                        {
                                                            text:`${data?.penandatangan?.grade?.name ? data?.penandatangan?.grade?.name : "-"} / Nip : ${data?.penandatangan?.nip ? data?.penandatangan?.nip : "-"}`,
                                                            alignment:"center",
                                                            lineHeight:0.7
                                                        },
                                                    ]
                                                ]
                                            }
                                        }
                                    ],
                                    // [
                                    //     {
                                    //         margin:[0, 30],
                                    //         border: [true, false, true, false],
                                    //         columns:[
                                    //             {text:""},
                                    //             {
                                    //                 text:`${data?.penandatangan?.position ? data?.penandatangan?.position?.name : "-"}`,
                                    //                 alignment:"center",
                                    //             }
                                    //         ]
                                    //     },
                                    // ],
                                    // [
                                    //     {
                                    //         border: [true, false, true, false],
                                    //         columns:[
                                    //             {text:""},
                                    //             {
                                    //                 text:`${data?.penandatangan?.name ? data?.penandatangan?.name : "-"}`,
                                    //                 alignment:"center",
                                    //                 decoration:"underline"
                                    //             },
                                    //         ]
                                    //     },
                                    // ],
                                    // [
                                    //     {
                                    //         border: [true, false, true, false],
                                    //         columns:[
                                    //             {text:""},
                                    //             {
                                    //                 text:`${data?.penandatangan?.grade?.name ? data?.penandatangan?.grade?.name : "-"}`,
                                    //                 alignment:"center",
                                    //                 lineHeight:0.7
                                    //             },
                                    //         ]
                                    //     },
                                    // ],
                                    // [
                                    //     {
                                    //         border: [true, false, true, true],
                                    //         columns:[
                                    //             {text:""},
                                    //             {
                                    //                 text:`Nip : ${data?.penandatangan?.nip ? data?.penandatangan?.nip : "-"}`,
                                    //                 alignment:"center",
                                    //                 lineHeight:0.7
                                    //             },
                                    //         ]
                                    //     },
                                    // ],
                                  ],
                                },
                        },
                        "",
                    ],
                  ],
                },
            },
            
        ]
        return sign

    }
    const tembusanSec = (data) => {
        const reformatData = []

        if (Array.isArray(data?.tembusan)) {
            data?.tembusan?.map((data) => (
                reformatData.push(`${data}`)
            ))
        } else {
            reformatData.push(`${data?.tembusan}`)
        }

        // if (Array.isArray(data?.tembusan)) {
        //     reformatData = `Yth. ${data?.tembusan}`
        // } else {
        //     reformatData.push(`Yth. ${data?.tembusan}`)
        // }

        if (data?.tembusan) {
            return (
                [{text:"TEMBUSAN :", decoration:"underline", margin:[0, 20, 0, 0] }, {ol:[...reformatData]}]
            )
        } else {
            return (
                [{text:" "}]
            )
        }
    }

    const tembusanSec2 = (data) => {
        const reformatData = []

        if (Array.isArray(data?.tembusan)) {
            data?.tembusan?.map((data) => (
                reformatData.push(`${data}`)
            ))
        } else {
            reformatData.push(`${data?.tembusan}`)
        }

        // if (Array.isArray(data?.tembusan)) {
        //     reformatData = `Yth. ${data?.tembusan}`
        // } else {
        //     reformatData.push(`Yth. ${data?.tembusan}`)
        // }

        if (data?.tembusan) {
            return (
                [{text:"Tembusan :", margin:[0, 20, 0, 0] }, {ol:[...reformatData]}]
            )
        } else {
            return (
                [{text:" "}]
            )
        }
    }


    const tembusanLowCase = (data) => {
        const reformatData = []

        if (Array.isArray(data?.tembusan)) {
            data?.tembusan?.map((data) => (
                reformatData.push(`${data}`)
            ))
        } else {
            reformatData.push(`${data?.tembusan}`)
        }

        // if (Array.isArray(data?.tembusan)) {
        //     reformatData = `Yth. ${data?.tembusan}`
        // } else {
        //     reformatData.push(`Yth. ${data?.tembusan}`)
        // }

        if (data?.tembusan) {
            return (
                [{text:"Tembusan :", decoration:"underline", margin:[0, 20, 0, 0] }, {ol:[...reformatData]}]
            )
        } else {
            return (
                [{text:" "}]
            )
        }
    }


    const generatePDF = (data, reportId, reportTitle, reportType, setDataUrl, content) => {

        const includeHeader_ = []

        if (reportType !== 'DIN' && parseInt(reportId) >= 12 && parseInt(reportId) <= 14) {
            includeHeader_.push({ 
                text: 'RAHASIA', 
                color: 'gray',
                margin: 25,
                alignment: 'center', 
            })
        } else if (parseInt(reportId) === 4 || parseInt(reportId) === 5 || parseInt(reportId) === 6 || parseInt(reportId) === 7 || parseInt(reportId) === 8 || parseInt(reportId) === 9 || parseInt(reportId) === 24) {
            includeHeader_.push({ 
                text: 'RAHASIA', 
                color: 'gray',
                margin: 25,
                alignment: 'center', 
            })
        } else if (reportType === "LIN" && parseInt(reportId) === 1) {
            includeHeader_.push({ 
                text: 'RAHASIA', 
                color: 'gray',
                margin: 25,
                alignment: 'center', 
            })
        }

        let name = `${reportType}.${reportId} - ${reportTitle}`

        if (reportType !== "" && reportId !== "") { 
            name = `${reportType}.${reportId} - ${reportTitle}`
        } else {
            name = `${reportTitle}`
        }

        console.log("data123", data)

        const dd = {
            info: {
                title: `${name} (
                    ${
                        data?.judul ? 
                            data?.judul 
                        :
                        data?.nomor_surat ? 
                            data?.nomor_surat
                        : 
                        data?.sumber_surat?.nomor_surat ? 
                            data?.sumber_surat?.nomor_surat
                        : 
                        data?.nomor_dokumen ? 
                            data?.nomor_dokumen
                        : 
                        data?.nomor ?
                            data?.nomor
                        :   
                        data?.tanggal ? 
                            !isNaN(parseInt(data?.tanggal)) ? (
                                moment.unix(data?.tanggal).format("DD MMMM YYYY")
                            ) : data?.tanggal ? (
                                moment(data?.tanggal[0]).format("DD MMMM YYYY")
                            ) : (
                                moment().format("DD MMMM YYYY")
                            )
                        : data?.judul_berita ? 
                            data?.judul_berita
                        : "-"
                    }
                )`,
            },
            pageOrientation : 'portrait',
            pageMargins: [85, 56.7, 56, 70],
            pageSize:{
                width:609,
                height:935,
            },
            header: {
                font:"bookman",
                columns: includeHeader_
            },
            content:content,
            pageBreakBefore: function (currentNode) {
                if (currentNode.id === "signature" && (currentNode.pageNumbers.length !== 1 || currentNode.pageNumbers[0] !== currentNode.pages)) {
                    return true

                } else {
                    return false
                }
            },
            kejaksaan: {
                url: 'https://upload.wikimedia.org/wikipedia/commons/b/b6/Kejaksaan_Agung_Republik_Indonesia_new_logo.png',
            },
            defaultStyle: {
                font:"bookman",
                fontSize:11,
                alignment:"justify"
            }
        }
    
    const titleDoc = data?.judul ? data?.judul 
                        : data?.nomor_surat ? 
                            data?.nomor_surat
                        : data?.sumber_surat?.nomor_surat ? 
                            data?.sumber_surat?.nomor_surat
                        : data?.nomor_dokumen ? 
                            data?.nomor_dokumen
                        : data?.nomor ?
                            data?.nomor
                        : data?.tanggal ? 
                            moment.unix(data?.tanggal).format("DD MMMM YYYY")
                        : data?.judul_berita

    if (setDataUrl && setDataUrl !== "download") {
        const pdf = pdfMake.createPdf(dd)

        let name = `${reportType}.${reportId} - ${reportTitle} (${titleDoc})`

        if (reportType !== "" && reportId !== "") { 
            name = `${reportType}.${reportId} - ${reportTitle} (${titleDoc})`
        } else {
            name = `${reportTitle} (${titleDoc})`
        }

        pdf.options = {
            defaultFileName: `
                ${name}
            `
        }
        pdf.getDataUrl((url) => {
            setDataUrl(url)
        })

        } else if (setDataUrl === "download") {
            if (reportType !== "" && reportId !== "") {
                return pdfMake.createPdf(dd).download(
                    `${reportType}.${reportId} - ${reportTitle} (${titleDoc}).pdf`
                )
            } else {
                return pdfMake.createPdf(dd).download(
                    `${reportTitle} (${titleDoc}).pdf`
                )
            }
           
        } else {
            return pdfMake.createPdf(dd).open({}, window.open('', '_blank'))
        }

    }


export { 
    headerSection, 
    headerSectionType2,
    headerSectionType3,
    headerSectionType4,
    headerSectionBigLetter,
    headerSectionWithoutKejaksaan, 
    subHeaderDefault, 
    subHeaderLampiran, 
    subHeaderIN20,
    removeTags, 
    content, 
    signDefault, 
    signDateTop, 
    signDateTopIN20,
    signDateTopLIN1,
    signDateTopNoPositionNip,
    signJabatanTop, 
    signWithNipPosition,
    signAutentikasi, 
    signRecipt, 
    signReciptMengetahuiMembuat,
    generatePDF, 
    tembusanSec, 
    tembusanSec2,
    tembusanLowCase,
    subHeaderWithLetterNumber, 
    signPelaksanaOperasi, 
    subHeaderText,
    subHeaderDefaultNoNumber
}
