import {
    Card,
    CardHeader,
    CardBody,
    Col,
    Input,
    Button,
    CardFooter,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    CardImg
}                   from "reactstrap"

import {
    Globe,
    MoreVertical,
    Share, Share2
}                   from "react-feather"

import Avatar       from '@components/avatar'
import moment from "moment/moment"
import Helper from "../../../helper"
import ImageCarousel from "../../../views/siacc/component/ImageCarousel"

const AgentReportCard = ({
    data,
    isDetail,
    setSelected,
    selected
}) => {

    const {
        id,
        how,
        what,
        when_ : when, //change key 
        why,
        who,
        where,
        created_at
    } = data

    const countWord = (string) => {
        return string.split(' ').length
    }


    const shortenWord = (string) => {

        if (countWord(string) < 100) {
            return string
        }

        return string.substr(0, string.lastIndexOf(' ', 950))
    }

    const { fallbackImage_ }    = Helper
   
    return (
        <>
            <div className="col">
                {
                    data !== null &&
                    <Card
                        className="h-100 p-0 m-0"
                    >
                        <CardHeader
                            className   = "border-bottom p-2 mb-1"
                        >
                            <div
                                className="col-12 d-flex mb-2 justify-content-end"
                            >
                                {/* <div className="d-flex">
                                    <Input
                                        type        = "checkbox"
                                        className   = "me-1 m-0 cursor-pointer"
                                        onChange    = {(e) => {
                                            if (setSelected) {
                                                console.log("AgentReportCard", e.target.checked)
                                                if (e.target.checked) {
                                                    setSelected(data)
                                                } else {
                                                    setSelected(null)
                                                }
                                            }
                                        }}
                                        checked     = {selected === data}
                                    />

                                    <UncontrolledDropdown className=''>
                                        <DropdownToggle className='cursor-pointer' tag='span'>
                                            <Share2 size={18} />
                                        </DropdownToggle>
                                        <DropdownMenu start>
                                            <DropdownItem className='d-flex align-items-center w-100'>
                                                Jadikan Laporan Ke LAPHASTUG/ LAOPSIN
                                            </DropdownItem>
                                            <DropdownItem className='d-flex align-items-center w-100'>
                                                Jadikan Laporan Ke LAPINHAR/ LAPSUS/ LAPINSUS
                                            </DropdownItem>
                                        </DropdownMenu>
                                    </UncontrolledDropdown>
                                </div> */}
                                <div>
                                    <Globe
                                        size        = {18}
                                        style       = {{
                                            marginRight : '5px'
                                        }}
                                    />
                                    {data?.kind === 1 ? 'Lokal' : 'Nasional'}
                                </div>
                            </div> 
                            
                            <div className="col-12 d-flex justify-content-between">
                                <div className="d-flex">
                                    <div>
                                        <Avatar
                                            img={data?.employee?.photo ? data?.employee?.photo : 'https://i.pinimg.com/originals/d2/98/4e/d2984ec4b65a8568eab3dc2b640fc58e.jpg'}
                                            size        = "md"
                                            onError     = {fallbackImage_}
                                            className   = "me-1 p-0"
                                        />
                                    </div>
                                    <div>
                                        <strong>
                                            <div>
                                                {data?.employee?.name} - {data?.employee?.workunit_level} {data?.employee?.workunit}
                                            </div>
                                        </strong>
                                        <div
                                            className   = "text-muted"
                                        >
                                            {moment(data?.created_at).startOf('hour').fromNow()} - {data?.location_name}
                                        </div>
                                    </div>
                                </div>
                                {/* <div>
                                    <Button
                                        color       = "transparent"
                                        size        = "sm"
                                        className   = "p-0 m-0"
                                    >
                                        <MoreVertical
                                            size    = {18}
                                        />
                                    </Button>
                                </div> */}
                            </div>
                        </CardHeader>
                        <CardBody
                            className="m-0"
                        >
                            <h4>{data?.title}</h4>
                            
                            {
                                data?.attachment?.length > 0  && <ImageCarousel images={data?.attachment}/>
                   
                            }
                           
                            {/* <div
                                className       = "mb-1"
                            >
                                {
                                    data?.attachment !== null &&
                                        data?.attachment?.map((dt) => {
                                            return (
                                                <>
                                                    {
                                                        dt.type === "image" &&
                                                        <img
                                                            style       = {{
                                                                width       : '100%',
                                                                height      : '250px',
                                                                objectFit   : 'covers'
                                                            }}
                                                            className   = "h-100 col-12 rounded"
                                                            src         = {dt.url}
                                                        />
                                                    }
                                                </>
                                            )
                                        })
                                }
                            </div> */}
                            <div
                                align="justify"
                            >
                                {
                                    shortenWord(`${when} Bertempat di. ${where}. ${who}. ${what}. ${why} . ${how}`)
                                }
                                
                            </div>
                        </CardBody>
                        <CardFooter>
                            Di Publikasi {data?.status === 1 ? 'Lokal' : 'Nasional'}
                        </CardFooter>
                        
                    </Card>
                }
            </div>
        </>
    )
}

export default AgentReportCard