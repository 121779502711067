import { Button, Input, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap"


//Context
import { ReportContext }        from "../../../context/reportContext"
import { useContext, useEffect, useState } from "react"
import DeclineModal from "./decline"
import reportAPI from "../../../service/report"

const ModalApprovement = (props) => {

    const {
        getData,
        reportData,
    }                  = props

    const {
        dataSelected,
        setDataSelected,
        originalData,
        setOriginalData,
        setShowModalPreview,
        showModalApprovement,
        setShowModalApprovement,

        handleApprovement
    }                                           = useContext(ReportContext)

    const [reportNumber, setReportNumber]       = useState(null)
    const [declineReason, setDeclineReason]     = useState("null")
    const [showDeclineForm, setShowDeclineForm] = useState(false)

    const createReportNumber = () => {
		const params = {
            sign_id        : dataSelected?.penandatangan?.user_id,
			report_type_id : reportData?.reportTypeId,
            report_id      : dataSelected?.report_id
		}

		// reportAPI.getReportNumber(params)
		// .then(
		// 	res => {
        //         // set report number
		// 		setReportNumber(res.data.number)

        //         // update data selected
        //         const data_ = {...dataSelected}
        //         data_.nomor_surat = res.data.number
        //         setDataSelected(data_)

        //         const original_ = {...originalData}
        //         original_.nomor_surat = res.data.number
        //         setOriginalData(original_)

		// 	}
		// ).catch(
		// 	err => {
		// 		console.log(err, 'create reprt')
		// 	}
		// )
	}

    useEffect(() => {
        console.log("dataSelected123", dataSelected)
        setReportNumber(dataSelected?.nomor_surat)
    }, [dataSelected])

    return (
        <>
            <DeclineModal
                declineReason = {declineReason}
                showDeclineForm = {showDeclineForm}
                setDeclineReason = {setDeclineReason}
                handleApprovement = {handleApprovement}
                setShowDeclineForm = {setShowDeclineForm}
                {...props}
            />

            <Modal
                style       = {{maxWidth: '800px', width: 800, height:1000}}
                isOpen      = {showModalApprovement}
                onOpened    = {() => { createReportNumber() }}
                centered    = {true}
            >
                <ModalHeader
                    toggle = {() => {
                        setShowModalApprovement(false)
                    }}
                >
                    Persetujuan Surat
                </ModalHeader>

                <ModalBody className="my-3">
                    <div className="text-center">
                        <strong>{reportData?.reportTitle}</strong> Nomor : {reportNumber}

                        <h3 className="my-1 text-primary"> <strong> MEMINTA PERSETUJUAN ANDA </strong></h3>

                        <p>
                            Surat ini meminta persetujuan dari anda sebagai pimpinan. <br/>
                            Surat ini akan berlaku sampai {reportData?.reportTypeId === "1" ? 7 : 30} hari setelahnya terhitung setelah anda menyetujui {reportData?.reportTitle} terkait.
                        </p>

                        <h4 className="cursor-pointer mt-3" onClick={() => { setShowModalPreview(true) }}>
                            <u>LIHAT SURAT</u>
                        </h4>
                    </div>
                </ModalBody>
                
                <ModalFooter className="d-flex justify-content-between">
                    <Button
                        color   = "danger"
                        onClick = {() => { 
                            setShowDeclineForm(true) 
                            setShowModalApprovement(false) 
                        }}
                        outline
                    >
                        Tidak Setuju
                    </Button>
                    <Button
                        color   = "primary"
                        onClick = {async() => { 
                            await handleApprovement(2, null, reportData) 
                            getData()
                        }}
                    >
                        Setuju
                    </Button>
                </ModalFooter>
            </Modal>
        </>
    )
}

export default ModalApprovement