import React, { 
    useState, 
    useEffect
}                           from 'react'

import Viewer               from 'react-viewer'
import {
    Carousel,
    CarouselItem,
    CarouselControl,
    CarouselIndicators,
    Card,
    CardImg,
}                           from 'reactstrap'
import ContainerFluid from './ContainerFluid'
// import ContainerFluid       from '../fluid'

const ImageCarousel = (props) => {

    const {
        images
    }                                   = props

    const [image, setImage]             = useState([])
    const [visible, setVisible]         = useState(false)
    const [animating, setAnimating]     = useState(0)
    const [activeIndex, setActiveIndex] = useState(0)

    console.log(activeIndex)
    
    const onExiting = () => {
        setAnimating(true)
    }
    
    const onExited = () => {
        setAnimating(false)
    }
    
    const next = () => {
        if (animating) return
        const nextIndex = activeIndex === images.length - 1 ? 0 : activeIndex + 1
        setActiveIndex(nextIndex)
    }
    
    const previous = () => {
        if (animating) return
        const nextIndex = activeIndex === 0 ? images.length - 1 : activeIndex - 1
        setActiveIndex(nextIndex)
    }
    
    const goToIndex = (newIndex) => {
        if (animating) return
        setActiveIndex(newIndex)
    }

    const reStructure = () => {
        const image_ = []
        
        images.map((data) => (
            image_.push({
                src : data.attachment,
                alt : data.Name,
                id  : data.id
            })
        ))

        setImage(image_)
    }

    useEffect(() => { 

        if (images !== undefined) {
            reStructure()
        }
    }, [])

    
    return (
        <>
            <Viewer
                images      = {image}
                visible     = {visible}
                onClose     = {() => { setVisible(false) }}
                activeIndex = {activeIndex}
            />

            <ContainerFluid className = "ml-0 mr-0" style={{zIndex: 999}}>
                <Carousel 
                    next        = {next} 
                    previous    = {previous} 
                    onExited    = {onExited}
                    activeIndex = {activeIndex} 
                >
                    {
                        images.length > 1 &&
                        <CarouselIndicators
                            items           = {images}
                            activeIndex     = {activeIndex}
                            onClickHandler  = {goToIndex}
                        />
                    }

                    {
                        images.map(item => (
                            <CarouselItem 
                                key         = {`attachment-image-${item.id}`}
                                onExited    = {onExited} 
                                onExiting   = {onExiting} 
                            >
                                <div 
                                    style  = {{ borderRadius:'10px', textAlign: 'center', padding: '7px'}}
                                    className= 'd-flex justify-content-center align-items-center bg-secondary'
                                >
                                    <Card 
                                        onClick     = {() => setVisible(true)}
                                        className   = 'w-75 mb-0'
                                        style       = {{maxHeight: '50vh'}}
                                    >
                                        <img 
                                            src     = {item.attachment} 
                                            alt     = {item.Name}
                                            style   = {{height: '35vh', width: '100%', objectFit: 'contain'}}
                                        />

                                        {/* <CardBody style={{padding: '0.5rem'}}>
                                            <span className='mb-0' style={{lineHeight: 0, textOverflow: 'ellipsis', wordWrap:'break-word'}}>{item.Name}</span>
                                        </CardBody> */}
                                    </Card>
                                </div>
                            </CarouselItem> 
                        ))
                    }

                    {
                        images.length > 1 &&
                        <>
                            <CarouselControl
                                direction       = 'prev'
                                directionText   = 'Previous'
                                onClickHandler  = {previous}
                            />
                            <CarouselControl
                                direction       = 'next'
                                directionText   = 'Next'
                                onClickHandler  = {next}
                            />
                        </>
                    }
                </Carousel>
            </ContainerFluid>
        </>
    )
}

export default ImageCarousel