import React, { useContext, useEffect, useState }   from 'react'
import { ArrowRight, ArrowLeft }                    from 'react-feather'
import { Button, Input, Modal, ModalHeader }        from 'reactstrap'
import { ReportContext }                            from '../../../context/reportContext'
import { getReportField }                           from '../../../utility/report-type-id'
import Select                                       from 'react-select'
import statusPenyelesaianOptionLin4                 from '../../../@core/components/input-dasar-surat/statusPenyelesaianOptionLin4'
import statusPenyelesaianOptionLin5                 from '../../../@core/components/input-dasar-surat/statusPenyelesaianOptionLin5'
import toast                                        from 'react-hot-toast'
import moment                                       from 'moment'
import reportAPI from '../../../service/report'

const ModalStatusAndInstruction = (props) => {

    const {
        show,
        data,
        onClose,
        handlePDF,
        reportData,
        setDataSelected,
        headerReport,
    }                                       = props

    const {
        data : listData,
		countCopies,
        handleUpdateCountCopies,
        reportTypeField,
        updateReport,
        handleAcceptTask,
        getData,
        dataSelected,
        setShowModalStatus,
        getDataById,
        createReport
	}				                        = useContext(ReportContext)

    const [dataUrl, setDataUrl]             = useState("")
    const [fetchStatus, setFetchStatus]     = useState(false)
    const [showDetail, setShowDetail]       = useState(false)
    const [status, setStatus]               = useState(false)
    const [keterangan, setKeterangan]		= useState("")
    const [sourceReport, setSourceReport]   = useState(null)

    const handlePdfType = async() => {
		//update count
		await handleUpdateCountCopies(data?.report_id, "pdf")

        await handlePDF(data, setDataUrl, countCopies?.pdf)
	}

    const handleCreateRin = async () => {
        
        const formData           = {
            sumber_surat                : data?.report_id,
            hasil_pelaksanaan_kegiatan  : "",
            keterangan                  : "",
        }

        let reportTypeFieldRin   = {}
        let reportTypeId         = 0
        let penandatangan
        const memerintahkan      = []

        if (sourceReport?.penandatangan?.user_id) {
            penandatangan = sourceReport?.penandatangan?.user_id
        } else {
            penandatangan = sourceReport?.penandatangan
        }
        formData.penandatangan = penandatangan

        if (Array.isArray(sourceReport.memerintahkan) && sourceReport.memerintahkan?.length > 0) {
            sourceReport.memerintahkan?.map((data) => {
                if (data.user_id) {
                    memerintahkan.push(data.user_id)
                } else {
                    memerintahkan.push(data)
                }
            })
		} else {
            if (sourceReport?.memerintahkan?.user_id) {
                memerintahkan.push(sourceReport?.memerintahkan?.user_id)
            } else {
                memerintahkan.push(sourceReport?.memerintahkan)
            }
        }
        formData.memerintahkan = memerintahkan

        if (data.report_type.id === 27) {
        // CREATE R.IN FROM L.IN.4

            // reportTypeId RIN.7
            if (sourceReport.bidang === "2" || sourceReport?.bidang?.id === 2) {
                reportTypeId = "59"

            // reportTypeId RIN.8
            } else if (sourceReport.bidang === "3" || sourceReport?.bidang?.id === 3) {
                reportTypeId = "60"

            // reportTypeId RIN.9
            } else if (sourceReport.bidang === "4" || sourceReport?.bidang?.id === 4) {
                reportTypeId = "61"

            // reportTypeId RIN.11
            } else if (sourceReport.bidang === "6" || sourceReport?.bidang?.id === 6) {
                reportTypeId = "63"
            }

        } else if (data.report_type.id === 28) {
        // CREATE R.IN FROM L.IN.5

            // reportTypeId RIN.12
            if (sourceReport.bidang === "2" || sourceReport?.bidang?.id === 2) {
                reportTypeId = "64"

            // reportTypeId RIN.13
            } else if (sourceReport.bidang === "3" || sourceReport?.bidang?.id === 3) {
                reportTypeId = "65"

            // reportTypeId RIN.14
            } else if (sourceReport.bidang === "4" || sourceReport?.bidang?.id === 4) {
                reportTypeId = "66"

            // reportTypeId RIN.15
            } else if (sourceReport.bidang === "5" || sourceReport?.bidang?.id === 5) {
                reportTypeId = "67"

            // reportTypeId RIN.16
            } else if (sourceReport.bidang === "6" || sourceReport?.bidang?.id === 6) {
                reportTypeId = "68"
            }
        }


        const getReportType = await reportAPI.getReportType(reportTypeId)
        reportTypeFieldRin  = await getReportType?.data?.field_report_type
        const newFieldData_ = getReportField(formData, reportTypeFieldRin)

        const finalFormData = {
            created_by          : sourceReport?.created_by,
            report_type_id 		: reportTypeId,
            report_values 		: newFieldData_,
            report_attachments 	: []
        }

        console.log("actionUpdate_finalFormData0", data)
        console.log("actionUpdate_finalFormData1", sourceReport)
        console.log("actionUpdate_finalFormData2", finalFormData)

        const otherAction = () => {
        }

        createReport(finalFormData, otherAction)
    }
    
    const actionUpdate = (reportId, formData) => {

		const newFieldData_ = getReportField(formData, reportTypeField)

		const finalFormData = {
			report_type_id 		: parseInt(reportData?.reportTypeId),
			report_values 		: newFieldData_,
			report_attachments 	: []
		}

		if (formData?.submit_type === "draft") {
            finalFormData.is_draft = true
        } else {
            finalFormData.is_draft = false
        }

        const otherAction = () => {
            const handleToastSuccess = () => {
                toast.success("Berhasil Selesaikan Surat")
                getData(reportData?.reportTypeId, headerReport)
                setShowModalStatus(false)
            }

            handleAcceptTask(reportData, data?.report_id, 7, handleToastSuccess)

            handleCreateRin()
        }

		updateReport(reportId, finalFormData, otherAction)
	}
    
    const handleSubmit = () => {

        if (!status) {
            toast.error("Pilih Status Terlebih Dahulu")
        } else if (keterangan === "") {
            toast.error("Isi Petunjuk Pimpinan Terlebih Dahulu")
        } else {
            const formData = data
            formData.petunjuk_pimpinan 			= keterangan
            formData.tanggal_petunjuk_pimpinan 	= moment(new Date()).unix()
            formData.status_penyelesaian        = status?.value

            if (formData.penandatangan) {
                formData.penandatangan = formData.penandatangan.user_id
            }
    
            if (formData.pelaksana_operasi.length > 0) {
                formData.pelaksana_operasi = formData.pelaksana_operasi.map((data) => (data.user_id))
            }

            if (formData.sumber_surat) {
                if (Array.isArray(formData?.sumber_surat) && formData?.sumber_surat?.length > 0) {
                    formData.sumber_surat = formData.sumber_surat.map((data) => data?.report_id)
                } else if (typeof formData.sumber_surat === 'object') {
                    if ("id" in formData.sumber_surat) {
                        formData.sumber_surat = formData?.sumber_surat?.id
                    } else {
                        formData.sumber_surat = formData?.sumber_surat?.report_id
                    }
                }
            }

            if (Array.isArray(formData?.sumber_kartutik) && formData?.sumber_kartutik?.length > 0) {
                formData.sumber_kartutik = formData.sumber_kartutik.map((data) => data?.report_id)
            } else {
                delete formData.sumber_kartutik
            }

            actionUpdate(data?.report_id, formData)
        }
	}

    useEffect(async () => {
        if (fetchStatus) {
            handlePdfType()
        }

        if (data) {
            if (data?.petunjuk_pimpinan && data?.petunjuk_pimpinan !== "__blank") {
                setKeterangan(data?.petunjuk_pimpinan)
            } else {
                setKeterangan("")
            }

            if (
                statusPenyelesaianOptionLin4.find((dt) => data?.status_penyelesaian?.includes(dt.value)) ||
                statusPenyelesaianOptionLin5.find((dt) => data?.status_penyelesaian?.includes(dt.value))
            ) {
                if (reportData?.reportTypeId === "27") {
                    console.log("data?.status_penyelesaian", data?.status_penyelesaian)
                    setStatus({
                        value : statusPenyelesaianOptionLin4.find((dt) => data?.status_penyelesaian?.includes(dt.value))?.value,
                        label : statusPenyelesaianOptionLin4.find((dt) => data?.status_penyelesaian?.includes(dt.value))?.label,
                    })
                } else {
                    setStatus({
                        value : statusPenyelesaianOptionLin5.find((dt) => data?.status_penyelesaian?.includes(dt.value))?.value,
                        label : statusPenyelesaianOptionLin5.find((dt) => data?.status_penyelesaian?.includes(dt.value))?.label,
                    })
                }
            }
        } else {
            setKeterangan("")
        }

    }, [data])

    useEffect(async () => {
        if (show && data) {
            if (data.report_type.id === 27) {
                if (Array.isArray(data?.sumber_surat)) {
                    setSourceReport(data?.sumber_surat.find((dt) => dt?.report_type?.id === 1))
                } else if (data?.sumber_surat?.memerintahkan) {
                    setSourceReport(data?.sumber_surat)
                } else {
                    const value_ = await getDataById(data?.sumber_surat, 1, true)
                    setSourceReport(value_)
                }
     
            } else {
                if (Array.isArray(data?.sumber_surat)) {
                    setSourceReport(data?.sumber_surat.find((dt) => dt?.report_type?.id === 3))
                } else if (data?.sumber_surat?.memerintahkan) {
                    setSourceReport(data?.sumber_surat)
                } else {
                    const value_ = await getDataById(data?.sumber_surat, 3, true)
                    setSourceReport(value_)
                }
            }
        }
    }, [show])

    return (
        <div>
            <Modal
                style       = {{maxWidth: '1320px', width: 1320, height:800}}
                isOpen      = {show}
                // size        = "xl"
                onOpened    = {() => handlePdfType()}
                onClosed    = {() => { 
                    setDataUrl("") 
                }}
            >
                <ModalHeader
                    toggle={() => {
                        onClose()
                        setFetchStatus(false)
                    }}
                >
                    Preview
                </ModalHeader>

                <div className='p-0 m-0'>
                    
                    <div className='d-flex justify-content-center1'>
                        <div className='col-8 bg-dark'>
                            <embed type='application/pdf' height={800} width={880} src={`${dataUrl}`}></embed>
                        </div>
                        <div className='col-4 bg-white p-2'>
                            <div
                                className='mb-2 h5'
                            >
                                Detail
                            </div>
                            <div>
                                <div
                                    className='mb-1'
                                >
                                    <div
                                        className='h6 m-0 p-0'
                                    >
                                        Nomor Surat
                                    </div>
                                    {data?.nomor_surat}
                                </div>
                                <div
                                    className='mb-1'
                                >
                                    <div
                                        className='h6 m-0 p-0'
                                    >
                                        Dibuat Pada
                                    </div>
                                    {data?.created_at}
                                </div>
                                <div
                                    className='mb-2'
                                >
                                    <div
                                        className='h6 m-0 p-0'
                                    >
                                        Dibuat Oleh
                                    </div>
                                    {data?.created_by?.name}
                                </div>
                                <div
                                    className='mb-2'
                                >
                                    <div
                                        className='h6 m-0 p-0'
                                    >
                                        Status Penyelesaian
                                    </div>
                                    <Select
                                        onChange            = {(e) => {
                                            setStatus(e)
                                        }}
                                        className           = {`react-select`}
                                        classNamePrefix     = "select"
                                        placeholder         = "Pilih Status Penyelesaian"
                                        options             = {reportData?.reportTypeId === "27" ? statusPenyelesaianOptionLin4 : statusPenyelesaianOptionLin5}
                                        value 				= {status}
                                    />
                                </div>

                                <div
                                    className='mb-2'
                                >
                                    <div
                                        className   = 'h6 m-0 p-0'
                                    >
                                        Petunjuk Pimpinan
                                    </div>

                                    <Input
                                        type		= "textarea"
                                        placeholder	= "Beri Petunjuk Pimpinan.."
                                        value 		= {keterangan}
                                        style       = {{
                                            marginTop : '7px',
                                            minHeight : '200px'
                                        }}
                                        onChange	= {(e) => {
                                            setKeterangan(e.target.value)
                                        }}
                                    />
                                
                                </div>

                                <div>
                                    <Button
                                        className   = 'col-12'
                                        color       = 'primary'
                                        onClick     = {() => {
                                            handleSubmit()
                                        }}
                                        style       = {{
                                            marginBottom : '5px'
                                        }}
                                    >
                                        {
                                            status ? "Selesai" : "Tanda Tangan"
                                        }
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        </div>
    )
}

export default ModalStatusAndInstruction