import { 
    Card, 
    CardBody, 
    Progress, 
    Table 
}                                           from "reactstrap"

import { PerformanceContext }               from "../../../context/performanceContext"
import { useContext, useEffect, useState }  from "react"
import PerformanceAPI                       from "../../../service/pages/performance"
import toast                                from "react-hot-toast"
import Avatar                               from '@components/avatar'
import First                                from '@src/assets/images/icons/first.png'
import Second                               from '@src/assets/images/icons/second.png'
import Third                                from '@src/assets/images/icons/third.png'


const ProfileRank = ({userBiodata}) => {
    const {
        detailLoading,
        detailWinner,
        getDetailDataAgent,
    }                                               = useContext(PerformanceContext)

    const [badge, setBadge]                         = useState([])


    const getPersonalBadge = () => {
        const params = {
            user_id : userBiodata?.user_id,
            period : new Date().getFullYear(),
            semester : 0,
        }

        PerformanceAPI.getPersonalBadge(params)
            .then(res => {
                // console.log('userBiodata', res?.results)
                setBadge(res?.results)
            })
            .catch((err) => {
                toast.error(err)
            })
    }
    
    useEffect(() => {
        if (userBiodata) {
            getDetailDataAgent({
                id          : userBiodata?.user_id, 
                role        : 0,
                kind        : 'national', 
                period      : new Date().getFullYear(), 
                semester    : 0
            })
            getPersonalBadge()
        }
    }, [userBiodata])
    return (
        <Card
            className="col-12 h-100 bg-white rounded m-0 text-dark"
        >
            <CardBody>
                {
                    !detailLoading ? (
                        <>
                            <div
                    className   = "d-flex"
                >
                    <div>
                        {
                            detailWinner[0]?.local_ranking === 1 || detailWinner[0]?.local_ranking === 2 || detailWinner[0]?.local_ranking === 3 ? (
                                <img 
                                    src     = {detailWinner[0]?.local_ranking === 1 ? First : detailWinner[0]?.local_ranking === 2 ? Second : Third} 
                                    width   = '50'
                                    height  = '50' 
                                />
                            ) : (
                                 <Avatar
                                    className   = "me-1 bg-primary"
                                    size        = "lg"
                                />
                            )
                        }
                       
                    </div>

                    <div>
                        Peringkat {detailWinner[0]?.local_ranking} <br/>
                        <strong>di {detailWinner[0]?.work_unit?.work_unit_level?.name} {detailWinner[0]?.work_unit?.name ? detailWinner[0]?.work_unit?.name : 'KEJAKSAAN AGUNG R.I'}</strong>
                    </div>
                </div>
               
                <div
                    className       = "py-1"
                >
                    <Table>
                        <tr>
                            <td className="">
                                Total Point
                            </td>
                            <td>

                            </td>
                            <td>
                                {detailWinner[0]?.total_point}
                            </td>
                        </tr>
                        <tr>
                            <td className="col-4">
                                Total Badge
                            </td>
                            <td>

                            </td>
                            <td>
                                {badge?.length}
                            </td>
                        </tr>
                    </Table>
                </div>

                <div className="d-flex justify-content-between">
                    <strong>EXP Point : {detailWinner[0]?.total_exp_point}/{detailWinner[0]?.max_xp_point}</strong>
                    <div className="h2 fw-bold"><strong className="text-dark">Lv.{detailWinner[0]?.level}</strong></div>
                </div>

                <div>
                <Progress
                    animated
                    color       = "primary"
                    value       = {(detailWinner[0]?.total_exp_point / detailWinner[0]?.max_xp_point) * 100}
                />
                </div>
                        </>
                    )
                    : (
                        <div className="d-flex justify-content-center align-items-center h-100">
                            <h4>Loading...</h4>
                        </div>
                    )
                }
                
            </CardBody>
        </Card>
    )   
}

export default ProfileRank