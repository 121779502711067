import { useContext, useEffect, useState } from "react"
import { useForm, Controller } from "react-hook-form"
import { 
    Form, 
    Label, 
    Modal, 
    Button, 
    ModalBody, 
    ModalHeader,
    ModalFooter,
    Input,
    Row,
    Col, 
} from "reactstrap"

import SubmitButton from "@components/submit-button"

import moment from "moment"
import { ReportContext } from "../../../context/reportContext"

const ModalFormFilterOrder = (props) => {

    const {
        show, 
        report,
        onClose,
        filteredField

    } = props
    
    const [loading, setLoading] = useState(false)
    const [filterField, setFilterField] = useState(null)

    const {
        params,
        setParams,
        setCurrentPage,
        reportTypeField
    } = useContext(ReportContext)

    const {
        control,
        handleSubmit,
        formState: { errors }
    } = useForm({
        mode: 'onChange'
    })

    const filterReportTypeField = () => {

        const newField_ = []

        reportTypeField.map((data) => {

            if (filteredField?.includes(data.name.replace(/-/g, '_')) && data.name !== 'sumber-surat') {
                const check = newField_.filter((dt) => dt.name === data?.name)
                if (check?.length === 0) {
                    newField_.push(data)
                }
            } 
        })

        const updatedData = newField_.map(item => {
            if (item.name === 'waktu-wawancara') {
              return { ...item, name: 'waktu-wawancara', id: 956 }
            }
            return item
          })

          console.log(updatedData, 'newField_')

        setFilterField(updatedData)
    }

    const onSubmit = (formData) => {
        setLoading(true)
        setCurrentPage(1)

        const params_ = {}

        if (report?.reportTypeId === '33' || report?.reportTypeId === '34' || report?.reportTypeId === '35' || report?.reportTypeId === '36') {
            params_.order_field = `${filterField[0]?.id},${filterField[1]?.id}`
        } else {
            params_.order_field = `${formData.field_name}`
        }

        setParams({...params_})
        onClose()
        setLoading(false)
    }

    const onReset = () => {

        setParams({
            page        : 1,
            limit       : 10
        })
        setCurrentPage(1)

        onClose()
        setFilterField(null)
    }

    useEffect(() => {
        if (reportTypeField) {
            filterReportTypeField()
        }
    }, [reportTypeField])
        
    return (
        <Modal
            isOpen = {show}
            centered = {true}
            unmountOnClose = {false}
        >
            <ModalHeader toggle={() => { onClose() }}>Urutkan Berdasarkan</ModalHeader>
            <Form onSubmit={handleSubmit(onSubmit)}>
                <ModalBody>
                    <Row>
                        {
                            report?.reportTypeId === '33' || report?.reportTypeId === '34' || report?.reportTypeId === '35' || report?.reportTypeId === '36' ? (
                                <Col md={6} className="d-flex align-items-center my-1">
                                    <Controller
                                        id		= {`field_name`}
                                        name	= {`field_name`}
                                        render	= {({ field }) => (
                                            <Input
                                                {...field}
                                                id			= "judul"
                                                type		= "radio"
                                                // value       = {data.id}
                                                style       = {{borderRadius: '75% !important'}}
                                            />
                                        )}
                                        control	= {control}
                                    />
                                    <Label className="ms-1 mb-0">Bulan Dan Tahun</Label>
                                </Col>
                            ) : 

                            filterField ?
                                filterField.map((data) => (
                                    <Col md={6} className="d-flex align-items-center my-1">
                                        <Controller
                                            id		= {`field_name`}
                                            name	= {`field_name`}
                                            render	= {({ field }) => (
                                                <Input
                                                    {...field}
                                                    id			= "judul"
                                                    type		= "radio"
                                                    value       = {data.id}
                                                    style       = {{borderRadius: '75% !important'}}
                                                />
                                            )}
                                            control	= {control}
                                        />
                                        <Label className="ms-1 mb-0">{data.name.replace(/-/g, ' ').toUpperCase()}</Label>
                                    </Col>
                                ))
                            :
                                null
                        }
                    </Row>
                </ModalBody>

                <ModalFooter className="d-flex justify-content-between">
                    <Button.Ripple
                        color="primary"
                        outline={true}
                        onClick={() => {
                            onReset()
                        }}
                    >
                        Reset
                    </Button.Ripple>

                    <SubmitButton
                        title   = "Filter Data"
                        loading = {loading}
                    />
                </ModalFooter>
            </Form>
        </Modal>
    )
}

export default ModalFormFilterOrder
