import { 
    Table,
    TextRun, 
    TableRow,
    Paragraph,
    TableCell,
    WidthType,
    ShadingType,
    AlignmentType,
}                           from 'docx'
import Helper                from '.'

const helper = Helper

let fontSizePdf = 11

const decodeHTMLEntities = (str) => {
    const textArea = document.createElement('textarea')
    textArea.innerHTML = str
    return textArea.value
    // return str
}

//GET TEXT AND SET PROPERTY BY PARENT TAGS
const handleAddProperty = (itemBody) => {
    const property = [
        {
            key_docx : "size",
            val_docx : 22,

            key_pdf : "fontSize",
            val_pdf : fontSizePdf,
        }
    ]

    if (itemBody?.className === 'marker') {
        property.push({
            key_docx : "highlight",
            val_docx : "yellow",

            key_pdf : "background",
            val_pdf : "yellow",
        })
    }
    
    if (itemBody?.style !== undefined && itemBody?.style?.color !== null && itemBody?.style?.color !== '') {
        const rgb = helper.rgba2hex(itemBody?.style?.color)
        property.push({
            key_docx : "color",
            val_docx : rgb,

            key_pdf : "color",
            val_pdf : `#${rgb}`,
        })
    }
    
    if (itemBody?.style !== undefined && itemBody?.style?.backgroundColor !== null && itemBody?.style?.backgroundColor !== '') {
        const rgb = helper.rgba2hex(itemBody?.style?.backgroundColor)

        property.push({
            key_docx : "shading",
            val_docx : {
                type    : ShadingType.SOLID,
                color   : rgb,
            },

            key_pdf : "background",
            val_pdf : `#${rgb}`,
        })
    }

    if (itemBody?.style !== undefined && itemBody?.style?.textAlign !== null && itemBody?.style?.textAlign !== '') {
        let _alignType = AlignmentType.LEFT

        if (itemBody?.style?.textAlign === "right") {
            _alignType = AlignmentType.RIGHT
        } else if (itemBody?.style?.textAlign === "center") {
            _alignType = AlignmentType.CENTER
        } else if (itemBody?.style?.textAlign === "justify") {
            _alignType = AlignmentType.JUSTIFIED
        } else {
            _alignType = AlignmentType.JUSTIFIED
        }
        property.push({
            key_docx : "alignment",
            val_docx : _alignType,

            key_pdf : "alignment",
            val_pdf : itemBody?.style?.textAlign,
        })

    }

    if (itemBody?.style !== undefined && itemBody?.style?.marginLeft !== null && itemBody?.style?.marginLeft !== '') {
        const ml = itemBody?.style?.marginLeft?.replace("px", "")
        property.push({
            key_docx : "margins",
            val_docx : {
                top     : 0,
                bottom  : 0,
                left    : parseInt(ml),
                right   : 0
            },

            key_pdf : "margin",
            val_pdf : [parseInt(ml), 0, 0, 0],
        })

    }
    
    if (itemBody.nodeName === "#text") {
        property.push({
            key_docx : "text",
            val_docx : decodeHTMLEntities(itemBody.data),

            key_pdf : "text",
            val_pdf : decodeHTMLEntities(itemBody.data),
        })
        property.push({
            key_docx : "size",
            val_docx : 22,

            key_pdf : "fontSize",
            val_pdf : fontSizePdf,
        })
    } else if (itemBody.nodeName === "STRONG") {
        property.push({
            key_docx : "bold",
            val_docx : true,

            key_pdf : "bold",
            val_pdf : true,
        })
    } else if (itemBody.nodeName === "EM") {
        property.push({
            key_docx : "italics",
            val_docx : true,

            key_pdf : "italics",
            val_pdf : true,
        })
    } else if (itemBody.nodeName === "U") {
        property.push({
            key_docx : "underline",
            val_docx : true,

            key_pdf : "decoration",
            val_pdf : "underline",
        })
    } else if (itemBody.nodeName === "S" || itemBody.nodeName === "DEL") {
        property.push({
            key_docx : "strike",
            val_docx : true,

            key_pdf : "",
            val_pdf : "",
        })
    } else if (itemBody.nodeName === "SUB") {
        property.push({
            key_docx : "subScript",
            val_docx : true,

            key_pdf : "",
            val_pdf : "",
        })
    } else if (itemBody.nodeName === "SUP") {
        property.push({
            key_docx : "superScript",
            val_docx : true,

            key_pdf : "",
            val_pdf : "",
        })
    }
    return property
}

//LOOP TAG CHILDREN & SET PROPERTY
const loopBodyHtml = (itemBody, row, property, body, type) => {
    const arr = Array.from(itemBody.childNodes)
    const property_ = (Array.isArray(property) && property.length > 0) ? property : [
        {
            key_docx : "size",
            val_docx : 22,

            key_pdf : "fontSize",
            val_pdf : fontSizePdf,
        }
    ]

    arr.map((itemBody, idx) => {
        const temp = []
        property_?.map((dt) => {
            temp.push(dt)
        })

        handleAddProperty(itemBody)?.map((dt) => {
            temp.push(dt)
        })
        
        if (itemBody.nodeName !== "#text") {
            loopBodyHtml(itemBody, `${row}-${idx}`, temp, body, type)
        } else {
            const _objProperty = {
                text        : itemBody.data
            }
            property_.map((dt) => {
                if (type === "docx") {
                    if (dt.key_docx !== "") {
                        _objProperty[dt.key_docx] = dt.val_docx
                    }
                } else if (type === "pdf") {
                    if (dt.key_pdf !== "") {
                        _objProperty[dt.key_pdf]  = dt.val_pdf
                    }
                }
            })
            body.push({
                text        : itemBody.data,
                property    : _objProperty
            })
        }
    })
}

const loopBodyHtmlList = (itemBody, row, property, body, type) => {
    const arr = Array.from(itemBody.childNodes)
    const property_ = (Array.isArray(property) && property.length > 0) ? property : [
        {
            key_docx : "size",
            val_docx : 22,

            key_pdf : "fontSize",
            val_pdf : fontSizePdf,
        }
    ]

    arr.map((itemBody, idx) => {
        const temp = []
        property_?.map((dt) => {
            temp.push(dt)
        })

        handleAddProperty(itemBody)?.map((dt) => {
            const filter = temp.filter((dtFilter) => dtFilter.key_pdf === dt.key_pdf)
            if (filter.length === 0) {
                temp.push(dt)
            }
        })
        
        if (itemBody.nodeName === "OL" || itemBody.nodeName === "UL") {
            const arrChild     = Array.from(itemBody.children)
            const _List   = []
            arrChild?.map((li) => {
                const tempChild    = [] 
                loopBodyHtmlList(li, row, property, tempChild, type)

                _List.push(tempChild)
            })

            body.push({
                type  : itemBody.nodeName,
                child : _List,
                list  : _List
            })
        } else if (itemBody.nodeName !== "#text") {
            loopBodyHtmlList(itemBody, `${row}-${idx}`, temp, body, type)
        } else {
            const _objProperty = {
                text        : itemBody.data.replace("\n\t", "")
            }
            property_.map((dt) => {
                if (type === "docx") {
                    if (dt.key_docx !== "") {
                        _objProperty[dt.key_docx] = dt.val_docx
                    }
                } else if (type === "pdf") {
                    if (dt.key_pdf !== "") {
                        _objProperty[dt.key_pdf]  = dt.val_pdf
                    }
                }
            })

            if (_objProperty.text !== "\n\t" && _objProperty.text !== "\t" && _objProperty.text !== "") {
                body.push({
                    text        : itemBody.data.replace("\n\t", ""),
                    property    : _objProperty
                })
            }

            
        }
    })
}

//CONVERT HTML BODY BY PARENT TYPE (TABLE/LIST/FREE TEXT)
const convertBodyHtmlParent = (itemBody, row, property, body, bodyFinal, type) => {
    if (itemBody.nodeName === "TABLE") {
        if (Array.from(itemBody.children)?.length > 0) {
            const arr = Array.from(itemBody.children)

            const _Table = []

            arr?.map((tbody) => {
                const arrTbody = Array.from(tbody.children)
                
                const _Tr  = []
                
                arrTbody?.map((tr) => {

                    const arrTr   = Array.from(tr.children)
                    const _Td     = []

                    arrTr?.map((td) => {

                        const temp = []
 
                        property?.map((dt) => {
                            temp.push(dt)
                        })

                        handleAddProperty(td)?.map((dt) => {
                            temp.push(dt)
                        })

                        body    = [] 
                        loopBodyHtml(td, row, temp, body, type)
                        _Td.push(body)
                    })

                    _Tr.push(_Td)
                })
                _Table.push(_Tr)

            })

            const property_ = {}
            if (itemBody.style.width !== '') {
                property_.width = {
                    size : parseInt(itemBody.style.width),
                    type : WidthType.DXA
                }
            }

            bodyFinal.push({
                type       : itemBody.nodeName,
                child      : _Table,
                property   : property_
            })
        }
    } else if (itemBody.nodeName === "OL" || itemBody.nodeName === "UL") {
        if (Array.from(itemBody.children)?.length > 0) {
            const arr     = Array.from(itemBody.children)
            const _List   = []
            arr?.map((li) => {
                body    = [] 
                loopBodyHtmlList(li, row, property, body, type)

                _List.push(body)
            })

            bodyFinal.push({
                type    : itemBody.nodeName,
                child   : _List
            })
        }
    } else if (
        itemBody.nodeName === "P"  || 
        itemBody.nodeName === "H1" || 
        itemBody.nodeName === "H2" ||
        itemBody.nodeName === "H3" ||
        itemBody.nodeName === "H4" ||
        itemBody.nodeName === "H5" ||
        itemBody.nodeName === "H6" ||
        itemBody.nodeName === "PRE" ||
        itemBody.nodeName === "ADDRESS" ||
        itemBody.nodeName === "DIV"
    ) {
        if (Array.from(itemBody.childNodes)?.length > 0) {
            loopBodyHtml(itemBody, row, property, body, type)
        
            bodyFinal.push({
                type    : "TEXT",
                child   : body
            })
        }
    }
}

const convertListStructureDocx = (list, body, key, sequence, type) => {
    const temp   = []
    const listType = type
    const checkChild = list.filter((dt) => dt.child)

    if (checkChild?.length > 0) {
        const paragraph = []
        list.map(item => {
            if (item.child) {
                // listType = item.type
                item.child.map((text, keyChild) => {
                    convertListStructureDocx(text, body, `${key}-${keyChild}`, keyChild, item.type)
                })
            } else {
                if (item.text !== "\n\t" && item.text !== "\t\t") {
                    paragraph.push(item)
                }
            }
        })
        temp.push(paragraph)
    } else {
        temp.push(list)
    }

    const level = key.split("-").length

    body.push({
        data  : temp,
        key   : key,
        level : level,
        sequence : sequence,
        type : listType
    })
    // return temp
}

const sortByHierarchyKey = (data) => {
    return data.sort((a, b) => {
        const aLevels = a.key.split("-").map(Number)
        const bLevels = b.key.split("-").map(Number)
        
        const maxLength = Math.max(aLevels.length, bLevels.length)

        // Bandingkan level per level hingga menemukan perbedaan
        for (let i = 0; i < maxLength; i++) {
            const aLevel = aLevels[i] !== undefined ? aLevels[i] : -1
            const bLevel = bLevels[i] !== undefined ? bLevels[i] : -1
            
            if (aLevel !== bLevel) {
                return aLevel - bLevel
            }
        }
        
        return 0
    })
}

//CREATE DOCX COMPS FROM HTML
const convertToDocx = (data, align, space, numberingId, marginLeft, marginLeftList, isTable) => {
    
    const result            = []
    const parser            = new DOMParser().parseFromString(data, "text/html")
    const htmlDoc           = parser.all

    const body                = htmlDoc[2]
    const bodyChildren        = Array.from(body.children)

    const bodyFinal = []

    bodyChildren?.map((itemBody, row) => {
        if (itemBody.nodeName !== "#text") {
            const tempBody     = []
            const tempProperty = []

            handleAddProperty(itemBody)?.map((dt) => {
                tempProperty.push(dt)
            })

            convertBodyHtmlParent(itemBody, row, tempProperty, tempBody, bodyFinal, "docx")
        }
    })


    bodyFinal?.map((dtParent) => {

        if (dtParent.type === "TABLE") {
            const property_      = dtParent.property_

            dtParent.child?.map((tr) => {
                //LOOP <TR> 
                const tableRow_      = []

                tr?.map((td) => {

                    //LOOP <TD> 
                    const tableCell_ = []

                    td?.map((textList) => {
                        //LOOP <TEXT> 
                        const paragraph = []
                        textList?.map((text) => {
                            paragraph.push(
                                new TextRun(text.property)
                            )
                        })
                        
                        tableCell_.push(
                            // paragraph
                            new TableCell({
                                children: [
                                    new Paragraph({
                                        children: paragraph,
                                        alignment : align === 'left' ? AlignmentType.LEFT : AlignmentType.JUSTIFIED,
                                        indent: {
                                            left: 360,  
                                            hanging: 360, 
                                          },
                                    })
                                ]
                            })
                        )
                    })

                    tableRow_.push(
                        new TableRow({
                            children: tableCell_
                        })
                    )
                })

                result.push(
                    new Table({
                        ...property_,
                        rows : tableRow_
                    })
                )

            })
        } else if (dtParent.type === "OL" || dtParent.type === "UL") {

            const body = []
            dtParent.child.map((textList, index) => {
                convertListStructureDocx(textList, body, `${index}`, index, dtParent.type)
            })

            const bodySorted = sortByHierarchyKey(body)

            console.log("bodySorted123", bodySorted)
            bodySorted?.map((itemList, index) => {
                itemList.data.map((textList) => {

                    //LOOP <TEXT> 
                    const paragraphNumber = [
                        new TextRun({text : `${itemList.sequence + 1}.`}),
                        new TextRun({
                            text: "   ", // Menambahkan spasi untuk efek margin kanan
                        }),
                    ]
                    const paragraph = []
                    textList?.map((text) => {
                        paragraphNumber.push(
                            new TextRun(text.property)
                        )
                        paragraph.push(
                            new TextRun(text.property)
                        )
                    })

                    const tableParagraph = []
                    textList?.map((text) => {
                        tableParagraph.push(
                            new TextRun({text : `${index + 1}.`}),
                            new TextRun({
                                text: "   ", // Menambahkan spasi untuk efek margin kanan
                            }),
                            new TextRun(text.property)
                        )
                    })
                    
                    const leftIndent = itemList?.level * 340

                    if (itemList.type === "UL") {
                        result.push(
                            new Paragraph({
                                children : paragraph,
                                alignment : align === 'left' ? AlignmentType.LEFT : AlignmentType.JUSTIFIED,
                                spacing: {
                                    after: index + 1 === dtParent.child?.length ? space === 0 ? 0 : 200 : 0,
                                },
                                bullet: {
                                    // level: itemList?.level ? itemList?.level : 0,
                                    level : 0
                                },
                                indent: {
                                    left: marginLeftList ? marginLeftList + leftIndent : 360 + leftIndent,  
                                    hanging: 360, 
                                },
                            }),
                        )
                    } else {
                        if (isTable) {  
                            result.push(
                                new Paragraph({
                                    children : tableParagraph,
                                    alignment : align === 'left' ? AlignmentType.LEFT : AlignmentType.JUSTIFIED,
                                    spacing: {
                                        after: index + 1 === dtParent.child?.length ? space === 0 ? 0 : 200 : 0,
                                    },
                                    indent: {
                                        // left: marginLeftList ? marginLeftList : 360,  
                                        left: marginLeftList ? marginLeftList + leftIndent : 360 + leftIndent,
                                        hanging: 360, 
                                    },
                                }),
                            )
                        } else {
                            result.push(
                                new Paragraph({
                                    children : paragraphNumber,
                                    alignment : align === 'left' ? AlignmentType.LEFT : AlignmentType.JUSTIFIED,
                                    // numbering: {
                                    //     reference: `${numberingId ? numberingId : "my-crazy-numbering"}`,
                                    //     level: itemList?.level - 1,
                                    //     restart: true,  
                                    // },
                                    spacing: {
                                        after: index + 1 === dtParent.child?.length ? space === 0 ? 0 : 200 : 0,
                                    },
                                    indent: {
                                        // left: marginLeftList ? marginLeftList : 360,  
                                        left: marginLeftList ? marginLeftList + leftIndent : 360 + leftIndent,
                                        hanging: 360, 
                                    },
        
                                }),
                            )
                        }
                    }
                })
            })
            
        } else if (dtParent.type === "TEXT") {
            const paragraph_      = []
            dtParent.child?.map((dt) => {
                paragraph_.push(new TextRun(dt.property))
            })

            result.push(
                new Paragraph({
                    alignment: align === "center" ? AlignmentType.CENTER : align === 'left' ? AlignmentType.LEFT : AlignmentType.JUSTIFIED,
                    spacing: {
                        after: space === 0 ? 0 : 200,
                    },
                    indent: {
                        left: marginLeft,
                    },
                    children: paragraph_
                })
            )
        }
    })

    if (result?.length > 0) {
        return result
    } else {
        result.push(
            new Paragraph({
                alignment: align === "center" ? AlignmentType.CENTER : align === 'left' ? AlignmentType.LEFT : AlignmentType.JUSTIFIED,
                spacing: {
                    after: space === 0 ? 0 : 200,
                },
                children: [new TextRun(decodeHTMLEntities(data))]
            })
        )

        return result
    }

}

function convertListStructurePdf(list, type) {
    let temp = {}

    const checkChild = list.filter((dt) => dt.child)

    if (checkChild?.length > 0) {
        const paragraph   = []
        const OL_LIST     = []
        let listType      = ""
        list.map(item => {
            if (item.child) {
                item.child.map((text) => {
                    listType = item.type
                    OL_LIST.push(convertListStructurePdf(text, item.type))
                })
            }
            if (item.property) {
                paragraph.push(item.property)
            }
        })

        if (listType === "OL") {
            temp = {
                stack : [
                    {text : paragraph},
                    {ol   : OL_LIST}        
                ]
            }
        } else {
            temp = {
                stack : [
                    {text : paragraph},
                    {ul   : OL_LIST}        
                ]
            }
        }

    } else {
        const paragraph = []
        list.map(item => {
            if (Array.isArray(item)) {
                item.map((dt) => {
                    paragraph.push(dt.property)
                })
            } else {
                paragraph.push(item.property)
            }
        })

        temp = {text : paragraph}
    }

    return temp
}

//CREATE PDFMAKE COMPS FROM HTML
const convertToPdf = (data, _fontSizePdf) => {
    
    if (_fontSizePdf) {
        fontSizePdf               = _fontSizePdf
    } else {
        fontSizePdf               = 11
    }
    
    const newdata             = data    
    const parser              = new DOMParser().parseFromString(newdata, "text/html")
    const htmlDoc             = parser.all

    const body                = htmlDoc[2]
    const bodyChildren        = Array.from(body.children)

    const bodyFinal = []

    bodyChildren?.map((itemBody, row) => {
        if (itemBody.nodeName !== "#text") {
            const tempBody    = []

            const tempProperty = []

            handleAddProperty(itemBody)?.map((dt) => {
                tempProperty.push(dt)
            })

            convertBodyHtmlParent(itemBody, row, tempProperty, tempBody, bodyFinal, "pdf")
        }
    })

    const content = []

    bodyFinal?.map((dtParent) => {
        if (dtParent.type === "TABLE") {

            dtParent.child?.map((tr) => {
                //LOOP <TR> 
                const tableRow_      = []
                const widths         = []

                tr?.map((td, idx) => {
                    //LOOP <TD> 
                    const tableCell_ = []

                    td?.map((textList) => {
                        if (idx === 0) {
                            widths.push("*")
                        }
                        //LOOP <TEXT> 
                        const paragraph = []
                        textList?.map((text) => {
                            paragraph.push(text.property)
                        })
                        
                        tableCell_.push({
                            text : paragraph
                        })
                    })

                    tableRow_.push(tableCell_)
                })

                content.push({
                    table: {
                        headerRows      : 1,
                        widths          : widths,
                        body            : tableRow_
                    }
                })

            })
        } else if (dtParent.type === "OL" || dtParent.type === "UL") {
            const _List = []

            dtParent.child.map((textList) => {
                const formatedList = convertListStructurePdf(textList, dtParent.type)
                _List.push(formatedList)
            })

            if (dtParent.type === "OL") {
                content.push({
                    ol: _List,
                    fontSize    : fontSizePdf,
                    margin: [0, 0, 0, 0]
                })
            } else {
                content.push({
                    ul: _List,
                    fontSize    : fontSizePdf,
                    margin: [0, 0, 0, 0]
                })
            }

        } else if (dtParent.type === "TEXT") {
            const paragraph_      = []
            dtParent.child?.map((dt) => {
                paragraph_.push(dt.property)
            })
            content.push({
                text : paragraph_
            })
        }
    })

    if (content?.length > 0) {
        return content
    } else {
        return {
            text        : decodeHTMLEntities(newdata),
            fontSize    : fontSizePdf
        }
    }
}

const HtmlConverter = {
    convertToDocx,
    convertToPdf
}

export default HtmlConverter