import { 
    Card, 
    CardBody, 
    ListGroup, 
    ListGroupItem, 
    Progress
}                           from "reactstrap"

import { 
    useEffect, 
    useState 
}                           from "react"

import { Line }             from "react-chartjs-2"
import { useParams }        from "react-router-dom"

import badge1               from "../../../../assets/images/icons/badge1.svg"
import badge2               from "../../../../assets/images/icons/badge2.svg"
import badge3               from "../../../../assets/images/icons/badge3.svg"
import badge4               from "../../../../assets/images/icons/badge4.svg"
import badge5               from "../../../../assets/images/icons/badge5.svg"
import badge6               from "../../../../assets/images/icons/badge6.svg"

import badgeLvl             from "../../../../assets/images/icons/badgeLvl.svg"
import badge                from "../../../../assets/images/icons/badge_rank.svg"
import badgeStar            from "../../../../assets/images/icons/badge_star.svg"
import userManagementAPI    from "../../../../service/pages/user-management"
import baseURL              from "../../../../service"
import Helper               from "../../../../helper"
import moment               from "moment"
import ProfileReport        from "../../../profile/component/profile_report"
import ProfileBiodata       from "../../../profile/component/profile_biodata"
import ProfileRank          from "../../../profile/component/profile_rank"
import ProfileReward        from "../../../profile/component/profile_reward"

const UserManagementDetail = ({
}) => { 

    const [data, setData]       = useState(null)
    const params                = useParams()
    const id                    = params.id
    
    const getDataDetail = (id) => {

        const payload = {
            id : id
        }

        userManagementAPI.getUserManagement(payload)
            .then((res) => {
                if (res?.results?.length > 0) {
                    setData(res.results[0])
                }
            })
    }

    console.log('userBiodata', data)
    
    useEffect(() => {
        getDataDetail(id)
    }, [id])

    return (
        <>
            <div
                className   = "row row-cols-lg-2 row-cols-1 g-1 mb-1"
            >
                <div className="col-lg-8 col-12">
                    <ProfileBiodata
                        userBiodata = {data}
                        isProfile   = {false}
                    />
                </div>
                
                <div className="col-lg-4 col-12">
                    <ProfileRank
                        userBiodata = {data}
                    />
                </div>
            </div>

            <div
                className   = "row row-cols-lg-2 row-cols-1 g-1"
            >
                <div className="col-lg-8 col-12">
                    <ProfileReport
                        userId = {data?.user_id}
                    />
                </div>
                
                <div className="col-lg-4 col-12">
                    <ProfileReward
                        userBiodata = {data}
                    />
                </div>
            </div>
        </>
    )
}

export default UserManagementDetail