import { 
    useContext, 
    useEffect, 
    useState 
}                               from "react"

import { PositionContext }      from "../../../context/positionContext"

import PositionTable            from "./component/PositionTable"
import PositionCreate           from "./component/PositionCreate"
import PositionUpdate           from "./component/PositionUpdate"
import ModalDelete              from "../../other/ModalDelete"
import positionAPI              from "../../../service/pages/position"
import toast                    from "react-hot-toast"

const PositionConfiguration = () => {

    const [selected, setSelected]                         = useState([])
    const [isCreateModalVisible, setIsCreateModalVisible] = useState(false)
    const [isUpdateModalVisible, setIsUpdateModalVisible] = useState(false)
    const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false)
    const [params, setParams]                             = useState({})

    const {
        getData,
        pagination,

        isLoading,

        listData,
        setListData
    }                                         = useContext(PositionContext)

    const [clearSelected, setClearSelected]   = useState(false)

    const handleCreate = (data) => {
        const payload = {
            name              : data?.name !== undefined ? data?.name : "",
            position_type     : data?.position_type !== undefined ? data?.position_type?.value : 0,
            description       : data?.description !== undefined ? data?.description : "",
            sector_id         : data?.sector !== undefined ? data?.sector?.value : 0,
            work_unit_level_id: data?.work_unit_level_id !== undefined ? data?.work_unit_level_id?.value : 0,
            work_unit_id      : data?.work_unit_id !== undefined ? data?.work_unit_id?.value : 0,
            parent            : data?.parent !== undefined ? data?.parent?.value : 0
        }

        positionAPI.createPosition(payload)
            .then((res) => {
                toast.success("Jabatan Berhasil Dibuat")
                getData()
                setIsCreateModalVisible(false)
            })
    }

    const handleUpdate = (data) => {
        const payload = {
            name              : data?.name !== undefined ? data?.name : "",
            position_type     : data?.position_type !== undefined ? data?.position_type?.value : 0,
            description       : data?.description !== undefined ? data?.description : "",
            sector_id         : data?.sector !== undefined ? data?.sector?.value : 0,
            work_unit_level_id: data?.work_unit_level_id !== undefined ? data?.work_unit_level_id?.value : 0,
            work_unit_id      : data?.work_unit_id !== undefined ? data?.work_unit_id?.value : 0,
            parent            : data?.parent !== undefined ? data?.parent?.value : 0
        }

        positionAPI.updateWorkunit(payload, selected[0]?.id)
            .then((res) => {
                toast.success("Data Jabatan Berhasil Diubah")
                getData()
                setIsUpdateModalVisible(false)
            })
    }

    const handleDelete = () => {
        if (selected.length > 0) {
            selected.map((data) => (
                positionAPI.deletePosition(data.id).then(
                    () => {
                        getData(params)
                    }
                ).catch( 
                    err => {
                        console.log(err)
                    }
                )
            ))

            setSelected([])
        }
    }

    useEffect(() => {
        if (clearSelected) {
            setClearSelected(!clearSelected)
        }
    }, [clearSelected])

    useEffect(() => {
        if (!isUpdateModalVisible) {
            setSelected([])
            setClearSelected(true)
        }
    }, [isUpdateModalVisible])

    useEffect(() => {
        if (!isDeleteModalVisible) {
            setSelected([])
            setClearSelected(true)
        }
    }, [isDeleteModalVisible])

    return (
        <>
            <PositionTable
                data                    = {listData}
                params                  = {params}
                setData                 = {setListData}
                getData                 = {getData}
                loading                 = {isLoading}
                selected                = {selected}
                setParams               = {setParams}
                pagination              = {pagination}
                setSelected             = {setSelected}
                clearSelected           = {clearSelected}
                setClearSelected        = {setClearSelected}
                setIsCreateModalVisible = {setIsCreateModalVisible}
                setIsUpdateModalVisible = {setIsUpdateModalVisible}
                setIsDeleteModalVisible = {setIsDeleteModalVisible}
            />

            <PositionCreate
                show        = {isCreateModalVisible}
                setShow     = {setIsCreateModalVisible}
                handleCreate= {handleCreate}
                title       = "Tambah Jabatan"
                size        = "lg"
            />

            <PositionUpdate
                show        = {isUpdateModalVisible}
                setShow     = {setIsUpdateModalVisible}
                handleUpdate= {handleUpdate}
                title       = "Ubah Jabatan"
                size        = "lg"
                data        = {selected?.length === 1 ? selected[0] : null}
            />

            <ModalDelete
                show        = {isDeleteModalVisible}
                setShow     = {setIsDeleteModalVisible}
                size        = "sm"
                title       = "Hapus Jabatan"
                handleDelete= {handleDelete}
                data        = {selected}
            />
        </>
    )
}

export default PositionConfiguration