import { 
	Fragment, 
	useContext, 
	useEffect, 
	useState 
} 						from "react"
import { 
	Controller, 
	useForm 
} 						from "react-hook-form"

import {
	Button,
	Col,
	Form,
	FormFeedback,
	Input,
	Label,
	Modal,
	ModalBody,
	ModalFooter,
	ModalHeader,
	Row,
	Table,
} 						from "reactstrap"

import {
	PrintIn6Schema,
	PrintIn7Schema,
	PrintIn8Schema,
	PrintLinSchema,
	PrintRinSchema,
	PrintDinSchema,
	PrintLinSchema29,
	PrintRinSchema74
} 								from "./validation"

import { yupResolver } 			from "@hookform/resolvers/yup"
import { selectThemeColors } 	from '@utils'

import toast                  	from "react-hot-toast"
import classnames	 			from "classnames"
import Select 					from "react-select"
import Flatpickr 				from "react-flatpickr"

import InputSumber 				from "../input-sumber"
import SubmitButton 			from "../submit-button"
import InputPenandatangan 		from "../input-penandatangan"
import LabelRequired 			from "../label-required"
import moment 					from "moment"
import reportAPI 				from "../../../service/report"
import MonthJSON 				from "../../../../src/views/reports/in/monthYearValue"
import 'moment/locale/id'

import { ReportContext }		from "../../../context/reportContext"

function ModalPrintSumber(props) {

    const { 
		show, 
		onClose, 
		type, 
		reportType, 
		reportNumber, 
		docxReport, 
		reportTypeId,
		pdfReport = null,
		din
	} = props

	const {
        getDataById,
		getDetailDataMap,
        getLastStatusApprovement,
    }                                                           = useContext(ReportContext)

	const category = [
		{id:1, value: 'RENCANA PENGAMANAN', 	label:'Rencana Pengamanan'}, 
		{id:2, value: 'RENCANA PENGGALANGAN', 	label:'Rencana Penggalangan'}
	]

	const penkum = [
		{value: 'Penyuluhan Hukum', 	label:'Penyuluhan Hukum'}, 
		{value: 'Penerangan Hukum', 	label:'Penerangan Hukum'}, 
	]

	const [monthList, setMonthList] 			= useState([])
	const [yearList, setYearList] 				= useState([])
    const [loading, setLoading] 				= useState(false)
	const [copies, setCopies] 					= useState(0)
	const [selected, setSelected] 				= useState(null)


    const getCopies = (kind) => {
        const params = {
			kind	  		: type === "preview" ? "pdf" : "word",
			report_type_id 	: parseInt(reportTypeId)
		}

		reportAPI.getReportCounting(params)
			.then((res) => {
				setCopies(res.count)
			})
    }

    const updateCopies = async (kind) => {
		const params = {
			kind	  		: type === "preview" ? "pdf" : "word",
			report_type 	: parseInt(reportTypeId)
		}

		await reportAPI.createReportCounting(params)
			.then((res) => {
				getCopies()
			})
	}

	useEffect(() => {
		setMonthList(MonthJSON.data_bulan)

		const tempYear 	= []
		let _start 		= moment().format("YYYY")
		const _end 		= 2020
		while (_start >= _end) {
			tempYear.push({
				label : _start,
				value : _start
			})
			_start--
		}

		setYearList(tempYear)
	}, [])

    useEffect(() => {
		if (show) {
			getCopies()
		}
    }, [show])

    const {
		reset,
		setValue,
		getValues,
		control,
		handleSubmit,
		formState: { errors },
	} = useForm({
		mode: "onChange",
		resolver: yupResolver(
			(reportType === "IN") ?
				reportNumber === "6" ? (
					PrintIn6Schema
				) : reportNumber === "7" ? (
					PrintIn7Schema
				) : reportNumber === "8" ? (
					PrintIn8Schema
				) : null
			: (reportType === "LIN") ? (
				reportNumber === "29" ? (
					PrintLinSchema29
				) : (
					PrintLinSchema
				)
			) : (reportType === "RIN") ? (
				reportNumber === "74" ? (
					PrintRinSchema74
				) : (
					PrintRinSchema
				)
			) : (reportType === "DIN") ? (
				PrintDinSchema
			) : null
		),
	})

    const onSubmit = async (dataForm) => {
		console.log("paramsReport", dataForm)

		setLoading(true)

		dataForm.pelaksana 	     = dataForm?.pelaksana?.name
		dataForm.sumber_suratId  = dataForm?.sumber_surat?.report_id
		dataForm.sumber_surat  	 = dataForm?.sumber_surat?.nomor_surat

		await updateCopies(type === "preview" ? "pdf" : "word")
		// console.log('dataForm', dataForm)
		const params = {
			kind	  		: type === "preview" ? "pdf" : "word",
			report_type_id 	: parseInt(reportTypeId)
		}
        toast.success('Mohon menunggu proses sedang berlangsung.')

		console.log(dataForm, selected)

		const paramsReport = {
			report_type_id 	: parseInt(reportTypeId),
			page_size : 1000,
			// start_created_at : moment(dataForm.tanggal_surat).locale("id").format("YYYY-MM-DD"),
			// end_created_at : moment(dataForm.tanggal_surat).locale("id").format("YYYY-MM-DD")
		}

		if (
			reportNumber === "6" ||
			reportNumber === "7" ||
			reportNumber === "8"
		) {
			paramsReport.source_report_id = dataForm.sumber_suratId
		} else if (dataForm.bulan === 0) {
			paramsReport.start_created_at = moment().year(dataForm?.tahun).startOf('year').format('YYYY-MM-DD')
			paramsReport.end_created_at = moment().year(dataForm?.tahun).endOf('year').format('YYYY-MM-DD')
		} else if (dataForm.tahun) {
			paramsReport.start_created_at = moment([dataForm?.tahun, dataForm?.bulan - 1]).startOf('month').format('YYYY-MM-DD')
			paramsReport.end_created_at = moment([dataForm?.tahun, dataForm?.bulan - 1]).endOf('month').format('YYYY-MM-DD')
		} else if (selected?.report_id) {
			paramsReport.source_report_id = selected?.report_id
		}

		console.log("paramsReport", paramsReport)
		const getReport = await reportAPI.getReport(paramsReport)

		const results_ = await Promise.all(
			await getReport.results.map(async(data) => {

				const reportData_   = await getDataById(data.id, data.report_type_id, true) 
				// const revisionData  = await data.report_approvement_report.filter((data) => (data.status_approvement === 4)) 

				const reformatData = reportData_[0]

				if (din) {
					const detailDin = await getDetailDataMap(reformatData, {din : din})
					reformatData.detailDin = detailDin
				}

				console.log("reformatData1234", reformatData)
				setLoading(false)

				return reformatData

			})
		)

		console.log(results_)

		// if ()

		reportAPI.getReportCounting(params)
			.then((res) => {
				const copies = res.count

				if (reportType === "IN") {
					setLoading(true)
					if (reportNumber === "6") {
						const dataFormPrint = {
							dibuatPada		: moment(dataForm.tanggal_surat).locale("id").format("DD MMMM YYYY"),
							copiesPDF		: copies,
							copiesWord		: copies,
							penandatangan1	: dataForm.penandatangan1,
							pelaksana		: dataForm.pelaksana,
							nomorSurat		: dataForm.sumber_surat,
							pokok_masalah	: dataForm.pokok_masalah,
							data			: results_
						}

						type === "preview" ? pdfReport(dataFormPrint) : docxReport(dataFormPrint)
						setLoading(false)
					} else if (reportNumber === '7') {
						const dataFormPrint = {
							dibuatPada		: moment(dataForm.tanggal_surat).locale("id").format("DD MMMM YYYY"),
							copiesPDF		: copies,
							copiesWord		: copies,
							penandatangan1	: dataForm.penandatangan1,
							penandatangan2	: dataForm.penandatangan2,
							nomorSurat		: dataForm.sumber_surat,
							tentang			: dataForm.tentang,
							data			: results_
						}
						type === "preview" ? pdfReport(dataFormPrint) : docxReport(dataFormPrint)
						setLoading(false)
					} else if (reportNumber === '8') {
						const dataFormPrint = {
							dibuatPada		: moment(dataForm.tanggal_surat).format("DD MMMM YYYY"),
							copiesPDF		: copies,
							copiesWord		: copies,
							penandatangan1	: dataForm.penandatangan1,
							penandatangan2	: dataForm.penandatangan2,
							nomorSurat		: dataForm.sumber_surat,
							kategori		: dataForm.kategori,
							tentang			: dataForm.tentang,
							data			: results_
						}
		
						type === "preview" ? pdfReport(dataFormPrint) : docxReport(dataFormPrint)
						setLoading(false)
					}
				} else if (reportType === "LIN" && reportNumber !== "29") {
					const dataFormPrint = {
						dibuatPada		: moment(dataForm.tanggal_surat).locale("id").format("DD MMMM YYYY"),
						copiesPDF		: copies,
						copiesWord		: copies,
						penandatangan 	: dataForm.penandatangan,
						tahun			: dataForm.tahun,
						bulan			: dataForm.bulan,
						data			: results_
					}

					type === "preview" ? pdfReport(dataFormPrint) : docxReport(dataFormPrint)
					setLoading(false)
				} else if (reportType === "RIN" && reportNumber !== "74") {
					const dataFormPrint = {
						dibuatPada		: moment(dataForm.tanggal_surat).format("DD MMMM YYYY"),
						copiesPDF		: copies,
						copiesWord		: copies,
						penandatangan1 	: dataForm.penandatangan1,
						penandatangan2 	: dataForm.penandatangan2,
						tahun			: dataForm.tahun,
						bulan			: dataForm.bulan,
						data			: results_

					}
					// console.log('dataForm', dataFormPrint)

					type === "preview" ? pdfReport(dataFormPrint) : docxReport(dataFormPrint)
					setLoading(false)
				}  else if (reportType === "DIN") {
					const dataFormPrint = {
						dibuatPada		: moment(dataForm.tanggal_surat).format("DD MMMM YYYY"),
						copiesPDF		: copies,
						copiesWord		: copies,
						penandatangan 	: dataForm.penandatangan,
						tahun			: dataForm.tahun,
						bulan			: dataForm.bulan,
						data			: results_
					}
					type === "preview" ? pdfReport(dataFormPrint) : docxReport(dataFormPrint)
					setLoading(false)
				} else if (reportType === "RIN" && reportNumber === "74") {
					const dataFormPrint = {
						dibuatPada		: moment(dataForm.tanggal_surat).format("DD MMMM YYYY"),
						copiesPDF		: copies,
						copiesWord		: copies,
						penandatangan1 	: dataForm.penandatangan1,
						penandatangan2 	: dataForm.penandatangan2,
						tahun			: dataForm.tahun,
						bulan			: dataForm.bulan,
						jenis_penkum    : dataForm.jenis_penkum, 
						data			: results_
					}
					type === "preview" ? pdfReport(dataFormPrint) : docxReport(dataFormPrint)
					setLoading(false)
				} else if (reportType === "LIN" && reportNumber === "29") {
					const dataFormPrint = {
						dibuatPada		: moment(dataForm.tanggal_surat).locale("id").format("DD MMMM YYYY"),
						copiesPDF		: copies,
						copiesWord		: copies,
						penandatangan1	: dataForm.penandatangan1,
						penandatangan2	: dataForm.penandatangan2,
						tahun			: dataForm.tahun,
						bulan			: dataForm.bulan,
						data			: results_

					}
					type === "preview" ? pdfReport(dataFormPrint) : docxReport(dataFormPrint)
					setLoading(false)
				} 

				onClose()
			})
	}

	const renderCompsSource = () => {
		return (
			<>
				<Col>
					<Label 
						for			= "sumber"
						className	= "form-label" 
					>
						Pilih Sumber <LabelRequired /> :
					</Label>

					<Controller
						id				= "sumber_surat"
						name			= "sumber_surat"
						control			= {control}
						render			= {({ field }) => (
							<InputSumber
								{...field}
								invalid			= {errors.sumber_surat}
								onSelect		= {(val) => { 
									field.onChange(val) 
								}}
								reportTypeId 	= {reportTypeId}
								setSelectedData = {setSelected}
							/>
						)}
					/>
					{errors.sumber_surat && <Label>{errors.sumber_surat.message}</Label>}
				</Col>
			</>
		)
	}

	const renderCompsUser = (label, key) => {
		return (
			<>
				<Col>
					<Label>
						{label} <LabelRequired />
					</Label>
					<Controller
						id		= {key}
						name	= {key}
						control	= {control}
						render	= {({ field }) => (
							<InputPenandatangan
								{...field}
								id 			= {key}
								className	= {`${errors[key] && "border-danger"}`}
								onChange	= {(item) => { field.onChange(item) }} 
							/>
						)}
					/>
					{errors[key] && (<small className="text-danger">Isian Tidak Boleh Kosong</small>)}
				</Col>
			</>
		)
	}

	const renderCompsDate = (label, key) => {
		return (
			<>
				<Col>
					<Label className="form-label" for={key}>
						{label} <LabelRequired /> :
					</Label>
					<Controller 
						id			= "" 
						name		= {key}
						control		= {control} 
						render		= {({ field }) => (
							<Flatpickr 
								{...field} 
								onChange	= {(tanggal_surat) => field.onChange(tanggal_surat)}
								className 	= {classnames("form-control", {
									"is-invalid- border-danger": errors[key] && true,
								})}
								options 	= {{ dateFormat: "d-m-Y" }}
								id 			= {key}
								placeholder = {label}
							/>
						)}
					/>
					{ errors[key] && (<small className="text-danger text-sm"> { errors[key].message } </small>)}
				</Col>
			</>
		)
	}

	const renderComsTextarea = (label, key) => {
		return (
			<>
				<Col>
					<Label 
						className	= "form-label" 
						for			= ""
					>
						{label} <LabelRequired />
					</Label>
					<Controller
						id			= {key}
						name		= {key}
						control		= {control}
						render		= {({ field }) => (
							<Input
								{...field}
								id			= {key}
								type		= "textarea"
								rows		= "3"
								invalid		= {errors[key] && true}
								placeholder	= {label}
							/>
						)}
					/>
					{errors[key] && <FormFeedback>{errors[key].message}</FormFeedback>}
				</Col>
			</>
		)
	}

	const renderCompsOption = (label, key, option) => {
		
		return (
			<Col>
				<Label className="form-label" for={key}>
					{label} <LabelRequired />
				</Label>
				<Controller 
					id			= {key}
					name		= {key}
					control		= {control} 
					render		= {({ field }) => (
						<Select 
							{...field} 
							id				= {key}
							theme			= {selectThemeColors} 
							classNamePrefix	= "select" 
							placeholder		= {label}
							value			= {field.Label} 
							className		= {classnames("react-select", { "is-invalid" : errors[key] && true, })}
							onChange		= {(kategori) => {
								field.onChange(kategori.value)
							}}
							options		 	= {option}
						/>
					)}
				/>
				{errors[key] && <small className="text-danger text-sm"> { errors[key].message } </small>}
			</Col>
		)
	}

	useEffect(() => {
		if (!show) {
			reset()
		}
	}, [show])

    return (
		<Fragment>
			<Modal
				size			= "lg"
				isOpen			= {show}
				unmountOnClose	= {true}
				onOpened		= {() => {
					reset({
						tanggal_surat	: "",
						penandatangan1	: "",
						penandatangan2	: "",
					})
					setValue('penandatangan1', "")
					setValue('penandatangan2', "")
				}}
			>
				<ModalHeader
					toggle={() => {
						onClose()
					}}
				>
					Cetak
				</ModalHeader>

				{
				type && (
					<Form onSubmit={handleSubmit(onSubmit)}>
						<ModalBody>
							{
								reportType === "IN" ?
									reportNumber === "6" ? 
										<>
											<Row className="mb-2">
												{renderCompsSource()}
											</Row>
											<Row className="mb-2">
												{renderCompsUser("Pilih Pelaksana", "pelaksana")}
											</Row>
											<Row className="mb-2">
												{renderCompsUser("Pilih Penandatangan 1", "penandatangan1")}
											</Row>
											<Row className="mb-2">
												{renderCompsDate("Tanggal Surat", "tanggal_surat")}
											</Row>
											<Row>
												{renderComsTextarea("Pokok Masalah", "pokok_masalah")}
											</Row>
										</>
									:
										<>
											<Row className="mb-2">	
												{renderCompsSource()}
											</Row>
											<Row className="mb-2">
												{
													reportNumber === '8' ? (
														renderCompsOption(
															"Pilih Kategori", 
															"kategori",
															category
														)
													) : null
												}

												{renderCompsDate("Tanggal Rencana Penyelidikan", "tanggal_surat")}
											</Row>

											<Row className="mb-2">
												{renderCompsUser("Pilih Penandatangan 1", "penandatangan1")}
											</Row>

											<Row className="mb-2">
												{renderCompsUser("Pilih Penandatangan 2", "penandatangan2")}
											</Row>
											
											<Row>
												{renderComsTextarea("Tentang", "tentang")}
											</Row>
										</>
								: null  
							}

							{
								(reportType === "LIN" || reportType === "DIN") && reportNumber !== "29" &&
								<>
									<Row className="mb-2">
										{
											renderCompsOption(
												"Pilih Bulan", 
												"bulan",
												monthList
											)
										}

										{
											renderCompsOption(
												"Pilih Tahun", 
												"tahun",
												yearList
											)
										}
									</Row>

									<Row className="mb-2">
										{renderCompsDate("Tanggal Surat", "tanggal_surat")}
									</Row>

									<Row className="mb-2">
										{renderCompsUser("Pilih Penandatangan", "penandatangan")}
									</Row>
								</>
							}

							{
								reportType === "RIN" &&
								<>
									<Row className="mb-2">
										{
											renderCompsOption(
												"Pilih Bulan", 
												"bulan",
												monthList
											)
										}

										{
											renderCompsOption(
												"Pilih Tahun", 
												"tahun",
												yearList
											)
										}
									</Row>

									{
										reportNumber === "74" &&
										<>
											<Row className="mb-2">
												{
													renderCompsOption(
														"Pilih Jenis Kegiatan Penkum", 
														"jenis_penkum",
														penkum
													)
												}
											</Row>
										</>
									}

									<Row className="mb-2">
										{renderCompsDate("Tanggal Surat", "tanggal_surat")}
									</Row>

									<Row className="d-flex mb-2">
										{renderCompsUser("Pilih Penandatangan 1", "penandatangan1")}

										{renderCompsUser("Pilih Penandatangan 2", "penandatangan2")}
									</Row>
								</>
							}

							{
								reportType === "LIN" && reportNumber === "29" &&
								<>
									<Row className="mb-2">
										{
											renderCompsOption(
												"Pilih Bulan", 
												"bulan",
												monthList
											)
										}

										{
											renderCompsOption(
												"Pilih Tahun", 
												"tahun",
												yearList
											)
										}
									</Row>

									<Row className="mb-2">
										{renderCompsDate("Tanggal Surat", "tanggal_surat")}
									</Row>

									<Row className="mb-2">
										<Col>
											{renderCompsUser("Pilih Penandatangan 1", "penandatangan1")}
										</Col>
										<Col>
											{renderCompsUser("Pilih Penandatangan 2", "penandatangan2")}
										</Col>
									</Row>

								</>
							}

						</ModalBody>

						<ModalFooter className="d-flex justify-content-between">
							<Button.Ripple
								color		= "primary"
								outline		= {true}
								onClick		= {() => {
									onClose()
								}}
							>
								Batal
							</Button.Ripple>

							<SubmitButton 
								loading		= {loading} 
								color		= "primary" 
								title		= "Cetak" 
							/>
						</ModalFooter>
					</Form>
				)}
			</Modal>
		</Fragment>
	)
}

export default ModalPrintSumber
