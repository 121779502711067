import { 
    TextRun, 
    ShadingType,
    Table,
    AlignmentType,
    TableRow,
    Paragraph,
    TableCell,
    WidthType, 
} from 'docx'
import moment from 'moment'
import fallback from '../assets/images/backgrounds/150x150.png'


const monthIndo = (paramsMonth) => {
    const month = parseInt(paramsMonth) + 1

    switch (month) {
        case 0:
            return " "
        case 1:
            return "Januari"
        case 2:
            return "Februari"
        case 3:
            return "Maret"
        case 4:
            return "April"
        case 5:
            return "Mei"
        case 6:
            return "Juni"
        case 7:
            return "Juli"
        case 8:
            return "Agustus"
        case 9:
            return "September"
        case 10:
            return "Oktober"
        case 11:
            return "November"
        case 12:
            return "Desember"
    }
}

const dateTimeIndo = (paramsDate) => {
    const date = new Date(paramsDate)

    return `${date.getDate()} ${monthIndo(date.getMonth())} ${date.getFullYear()}, ${date.getHours()}: ${date.getMinutes()}`
}
const dateIndo = (paramsDate) => {
    const date = new Date(paramsDate)

    return `${date.getDate()} ${monthIndo(date.getMonth())} ${date.getFullYear()}`
}

const dayIndo = (paramsDate) => {
    const date = new Date(paramsDate)
    switch (date.getDay()) {
        case 0:
            return "Minggu"
        case 1:
            return "Senin"
        case 2:
            return "Selasa"
        case 3:
            return "Rabu"
        case 4:
            return "Kamis"
        case 5:
            return "Jumat"
        case 6:
            return "Sabtu"
        default:
            return "Tes"
    }
}

const fileSize = (size) => {
    const kb = (size / 1024)
    if (kb < 1) {
        return `${size} bytes`
    } else {
        const mb = (kb / 1024)
        if (mb < 1) {
            return `${parseFloat(kb).toFixed(2)} kb`
        } else {
            return `${parseFloat(kb / 1024).toFixed(2)} mb`
        }
    }
}

const rgba2hex = (rgba) => `${rgba.match(/^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+\.{0,1}\d*))?\)$/).slice(1).map((n, i) => (i === 3 ? Math.round(parseFloat(n) * 255) : parseFloat(n)).toString(16).padStart(2, '0').replace('NaN', '')).join('')}`

const docxEditTextPDF = (data) => {
    const parser = new DOMParser()
    const htmlDoc = parser.parseFromString(data, "text/html")
    const tagP = htmlDoc.getElementsByTagName("p")

    if (tagP.length > 0) {
        const childrenTag = tagP[0].childNodes
        const htmlObject = document.createElement("span")
        for (let i = 0; i < childrenTag.length; i++) {
            switch (childrenTag[i].nodeName) {
                case '#text':
                    htmlObject.innerHTML = childrenTag[i].textContent
                case 'EM':
                    const tagEM = childrenTag[i].childNodes
                    for (let iEM = 0; iEM < tagEM.length; iEM++) {
                        switch (tagEM[iEM].nodeName) {
                            case '#text':
                                htmlObject.innerHTML = childrenTag[i].textContent
                                const resultEM = htmlObject.style.cssText = 'font-style: italic'
                                return resultEM
                            case 'INS':
                                htmlObject.innerHTML = childrenTag[i].textContent
                                const resultINS = htmlObject.style.cssText = 'font-style: italictext-decoration: underline'
                                return resultINS
                            default:
                                break
                        }
                    }
                    break
                case 'STRONG':
                    const tagSTRONG = childrenTag[i].childNodes
                    for (let iSTRONG = 0; iSTRONG < tagSTRONG.length; iSTRONG++) {
                        switch (tagSTRONG[iSTRONG].nodeName) {
                            case '#text':
                                htmlObject.innerHTML = childrenTag[i].textContent
                                const resultSTRONG = htmlObject.style.cssText = 'font-weight: bold'
                                return resultSTRONG
                            case 'INS':
                                htmlObject.innerHTML = childrenTag[i].textContent
                                const resultINS = htmlObject.style.cssText = 'font-weight: boldtext-decoration: underline'
                                return resultINS
                            case 'EM':
                                htmlObject.innerHTML = childrenTag[i].textContent
                                const resultEM = htmlObject.style.cssText = 'font-weight: boldfont-style: italictext-decoration: underline'
                                return resultEM
                            case 'DEL':
                                htmlObject.innerHTML = childrenTag[i].textContent
                                const resultDEL = htmlObject.style.cssText = 'font-weight: boldtext-decoration: line-through'
                                return resultDEL
                            default:
                                break
                        }
                    }
                    break
                default:
                    break
            }
        }
    } else {
        return data
    }
}

const docxEditTextLoop = (data, align, space) => {
    
    const result  = []
    const parser    = new DOMParser().parseFromString(data, "text/html")
    const htmlDoc   = parser.all

    const bodyChild = htmlDoc[2].children // get all body children element
    if (bodyChild.length > 0) {
        //check child element tag type
        for (let count_ = 0; count_ < bodyChild.length; count_++) {


            if (bodyChild[count_].nodeName === "TABLE") {
                const getChildren    = bodyChild[count_].children[0].children // to get table body children

                const property_      = {
                    width: {
                        size: 4535,
                        type: WidthType.DXA,
                    }
                }
                const tableRow_      = [] // for save table rows value

                if (bodyChild[count_].style.width !== '') {
                    property_.width = {
                        size : parseInt(bodyChild[count_].style.width),
                        type : WidthType.DXA
                    }
                }

                //loop for get tr element
                for (let counter_ = 0; counter_ < getChildren.length; counter_++) {
                    const tableRowChildren_ = [] // for save table cell 

                    const rowChildren_ = getChildren[counter_].children

                    if (rowChildren_.length > 0) {
                        //loop for get td element value
                        for (let counter2_ = 0; counter2_ < rowChildren_.length; counter2_++) {
                            tableRowChildren_.push(
                                new TableCell({
                                    children: [new Paragraph(rowChildren_[counter2_].textContent)],
                                })
                            )
                        }
                    }

                    tableRow_.push(
                        new TableRow({
                            children: tableRowChildren_
                        })
                    )
                }

                result.push(
                    new Table({
                        ...property_,
                        rows : tableRow_
                    })
                ) // push to end result

            } else if (bodyChild[count_].nodeName === "OL" || bodyChild[count_].nodeName === "UL") {
                const getChildren    = bodyChild[count_].children // to get OL children

                //loop for get li element
                for (let counter_ = 0; counter_ < getChildren.length; counter_++) {

                    const subChild = []
                    //condition for li child has style 
                    if (getChildren[counter_].childNodes.length > 0) {
                        // loop for insert ol element
                        for (let counter2_ = 0; counter2_ < getChildren[counter_].childNodes.length; counter2_++) {
                            const property_ = {}

                            if (getChildren[counter_].childNodes[counter2_].nodeName === "SPAN" || getChildren[counter_].childNodes[counter2_].nodeName === "P") {
                                
                                //add property from parent nodes
                                if (getChildren[counter_].childNodes[counter2_].className === 'marker') {
                                    property_.highlight = 'yellow'
                                } else if (getChildren[counter_].childNodes[counter2_].style.color !== '') {
                                    const rgb = rgba2hex(getChildren[counter_].childNodes[counter2_].style.color)
                                    property_.color = rgb
                                } else if (getChildren[counter_].childNodes[counter2_].style.backgroundColor !== '') {
                                    const rgb = rgba2hex(getChildren[counter_].childNodes[counter2_].style.backgroundColor)
            
                                    property_.shading = {
                                        type    : ShadingType.SOLID,
                                        color   : rgb
                                    }
                                } else if (getChildren[counter_].childNodes[counter2_].nodeName === "#text" || getChildren[counter_].childNodes[counter2_].nodeName === "P") {
                                    property_.text = getChildren[counter_].childNodes[counter2_].textContent
                                    property_.size = 22
                                } else if (getChildren[counter_].childNodes[counter2_].nodeName === "STRONG") {
                                    property_.bold = true
                                } else if (getChildren[counter_].childNodes[counter2_].nodeName === "EM") {
                                    property_.italics = true
                                } else if (getChildren[counter_].childNodes[counter2_].nodeName === "U") {
                                    property_.underline = true
                                } else if (getChildren[counter_].childNodes[counter2_].nodeName === "S" || getChildren[counter_].childNodes[0].nodeName === "DEL") {
                                    property_.strike = true
                                } else if (getChildren[counter_].childNodes[counter2_].nodeName === "SUB") {
                                    property_.subScript = true
                                } else if (getChildren[counter_].childNodes[counter2_].nodeName === "SUP") {
                                    property_.superScript = true
                                }
                            }

                            subChild.push(
                                new TextRun({
                                    text : getChildren[counter_].childNodes[counter2_].textContent,
                                    ...property_
                                })
                            )
                            
                        }

                        //for insert bullet or numbering list
                        if (bodyChild[count_].nodeName === "OL") {
                        result.push(
                            new Paragraph({
                                children : subChild,
                                numbering: {
                                    reference: "my-crazy-numbering",
                                    level: 0,
                                },
                                indent: {
                                    left: 360,  
                                    hanging: 360, 
                                  },
                                  size:22,
                                  alignment: AlignmentType.JUSTIFIED,
                            }),
                            
                        )
                        } else {
                            result.push(
                                new Paragraph({
                                    children : subChild,
                                    bullet: {
                                        level: 0,
                                    },
                                    indent: {
                                        left: 360,  
                                        hanging: 360, 
                                      },
                                      alignment: AlignmentType.JUSTIFIED,
                                })
                            )
                        }
                    } else {
                        if (bodyChild[count_].nodeName === "OL") {
                            result.push(
                                new Paragraph({
                                    children : [
                                        new TextRun({
                                            size:22,

                                            text : getChildren[counter_].textContent,
                                        })
                                    ],
                                    numbering: {
                                        reference: "my-crazy-numbering",
                                        level: 0,
                                    },

                                    indent: {
                                        left: 360,  
                                        hanging: 360, 
                                      },
                                      alignment: AlignmentType.JUSTIFIED,
                                })
                            )
                        } else {
                            result.push(
                                new Paragraph({
                                    children : [
                                        new TextRun({
                                            size:22,
                                            

                                            text : getChildren[counter_].textContent,
                                        })
                                    ],
                                    bullet: {
                                        level: 0,
                                    },

                                    indent: {
                                        left: 360,  
                                        hanging: 360, 
                                      },
                                      alignment: AlignmentType.JUSTIFIED,
                                })
                            )
                        }
                    }

                }

            } else if (bodyChild[count_].nodeName === "P" || bodyChild[count_].nodeName === "H1" || bodyChild[count_].nodeName === "H2") {
                //index 3 selected to ignore html, head, body tag
                const element = bodyChild[count_].childNodes
                const resultP = []

                for (let i = 0; i < element.length; i++) {
                    if (element[i].nodeName === "#text") {
                        resultP.push(new TextRun({text : element[i].textContent, size : 22}))
                    } else {
                        const childTag = element[i].getElementsByTagName("*") // to get all child
                        const property_ = {
                            text: element[i].textContent,
                            size: 22
                        }
    
                        //add property from parent nodes
                        if (element[i].className === 'marker') {
                            property_.highlight = 'yellow'
                        } else if (element[i].style.color !== '') {
                            const rgb = rgba2hex(element[i].style.color)
                            property_.color = rgb
                        } else if (element[i].style.backgroundColor !==  '') {
                            const rgb = rgba2hex(element[i].style.backgroundColor)
    
                            property_.shading = {
                                type    : ShadingType.SOLID,
                                color   : rgb
                            }
                        } else if (element[i].nodeName === "#text") {
                            property_.text = element[i].textContent
                            property_.size = 22
                        } else if (element[i].nodeName === "STRONG") {
                            property_.bold = true
                        } else if (element[i].nodeName === "EM") {
                            property_.italics = true
                        } else if (element[i].nodeName === "U") {
                            property_.underline = true
                        } else if (element[i].nodeName === "S" || element[i].nodeName === "DEL") {
                            property_.strike = true
                        } else if (element[i].nodeName === "SUB") {
                            property_.subScript = true
                        } else if (element[i].nodeName === "SUP") {
                            property_.superScript = true
                        }
    
                        //loop for add property from child nodes
                        for (let index = 0; index < childTag.length; index++) {
                            if (childTag[index].className === 'marker') {
                                property_.highlight = 'yellow'
                            } else if (childTag[index].style.color !== '') {
                                const rgb = rgba2hex(childTag[index].style.color)
                                property_.color = rgb
                            } else if (childTag[index].style.backgroundColor !== '') {
                                const rgb = rgba2hex(childTag[index].style.backgroundColor)
    
                                property_.shading = {
                                    type    : ShadingType.SOLID,
                                    color   : rgb
                                }
                            } else if (childTag[index].nodeName === "#text") {
                                property_.text = childTag[index].textContent
                                property_.size = 22
                            } else if (childTag[index].nodeName === "STRONG") {
                                property_.bold = true
                            } else if (childTag[index].nodeName === "EM") {
                                property_.italics = true
                            } else if (childTag[index].nodeName === "U") {
                                property_.underline = true
                            } else if (childTag[index].nodeName === "S" || childTag[index].nodeName === "DEL") {
                                property_.strike = true
                            }
                        }
                        
                        resultP.push(new TextRun(property_))
                    }
                    
                }

                result.push(
                    new Paragraph({
                        alignment: align !== 'left' ? AlignmentType.JUSTIFIED : AlignmentType.LEFT,
                        spacing: {
                            after: space === 0 ? 0 : 200,
                        },
                        children: resultP
                    })
                )
            }
        }
    }

    return result
}

const actionTypeIndo = (val) => {
    switch (val) {
        case "moved":
            return "Dipindahkan oleh "
            break
        case "created":
            return "Dibuat oleh "
            break
        case "copied":
            return "Disalin oleh "
            break
        case "renamed":
            return "Diubah oleh "
            break
        case "uploaded":
            return "Ditambahkan Oleh "
            break
    }
}

const accessRole = (val) => {
    switch (val) {
        case 0:
            return "Belum Ada Status "
            break
        case 1:
            return "Hanya Membaca "
            break
        case 2:
            return "Perbolehkan Unggah dan Ubah Berkas "
            break
        case 3:
            return "Hanya Bisa Unggah Berkas "
            break
    }
}

const getTimeAgo = (stringDate) => {

    moment.updateLocale('id', {
        relativeTime: {
            future: "dalam %s",
            past: "%s yang lalu",
            s: 'beberapa detik yang lalu',
            ss: '%d detik yang lalu',
            m: "semenit yang lalu",
            mm: "%d menit yang lalu",
            h: "satu jam yang lalu",
            hh: "%d jam yang lalu",
            d: "sehari yang lalu",
            dd: "%d hari yang lalu",
            w: "seminggu yang lalu",
            ww: "%d minggu yang lalu",
            M: "sebulan yang lalu",
            MM: "%d bulan yang lalu",
            y: "setahun yang lalu",
            yy: "%d tahun yang lalu"
        }
    })

    moment.locale('id')
    return moment(stringDate).fromNow(true)
}

const getTitleReportType = (type) => {
    if (type === "in") {
        return "IN"
    } else if (type === "lin") {
        return "L.IN"
    } else if (type === "rin") {
        return "R.IN"
    } else if (type === "din") {
        return "D.IN"
    }
}
const styleExpired = (value) => {
    if (new Date() > new Date(value)) {
        return "expired-card"
    }
    return false
}

const fallbackImage_ = (val) => {
    val.target.src = fallback
}

const reformatDate = (data, type) => {
    let hari = ''
    let tanggal = ''
    let jam = ""
    let year = ""
    let month = ""

    if (data) {
        if (typeof data === "string") {
            hari = dayIndo(moment.unix(data))
            tanggal = moment.unix(data).locale('id').format('DD MMMM YYYY')
            year = moment.unix(data).format('YYYY')
            jam = moment.unix(data).format("H:mm")
            month = moment.unix(data).format('MM')
        } else {
            hari = dayIndo(moment(data[0]))
            tanggal = moment(data[0]).locale('id').format('DD MMMM YYYY')
            year = moment(data).format('YYYY')
            jam = moment(data[0]).format("H:mm")
            month = moment(data).format('MM')
        }
    
         const condition = {
             "hour":jam,
            "day":hari,
            "date":tanggal,
            "month":month,
            "year":year,
         }
         return condition[type]
    } else {
        return "-"
    }

}

const shortenWord = (text, length) => {
    // Mengambil 7 karakter pertama
    const string_ = text.substring(0, length)

    // Menambahkan ellipsis jika panjang kata lebih dari 7 karakter
    const stringWithEllipsis_ = text.length > length ? `${string_}...` : string_

    return stringWithEllipsis_
}
const capitalizeFirstLetter = (stringWord) => {

    const word_     = stringWord?.toLowerCase()

    const array_    = word_.split(" ")
    const newWord_  = array_.map((word) => (word.charAt(0).toUpperCase() + word.slice(1)))
    const finalWord_ = newWord_.join(" ")

    return finalWord_
}

const redirectReport = (params) => {
    const queryParams = new URLSearchParams(params.search)
	  
    // Convert query parameters to an object
    const queryParamsObj = {}
    for (const [key, value] of queryParams) {
      queryParamsObj[key] = value
    }    

    return queryParamsObj?.report_id
}

const isNestedArray = (variable) => {
    if (Array.isArray(variable)) {
        // Check if any element in the array is itself an array (nested array)
        return variable.some(item => Array.isArray(item))
    }
    return false
}

const findReportSource = (source, report_type_id) => {
    if (!source) {
        return null 
    }

    if (source.report_type && source.report_type.id === report_type_id) {
        return source 
    }

    if (Array.isArray(source.sumber_surat)) {
        for (const item of source.sumber_surat) {
            const result = findReportSource(item, report_type_id) 
            if (result) {
                return result 
            }
        }
    }

    if (typeof source.sumber_surat === 'object' && source.sumber_surat !== null) {
        return findReportSource(source.sumber_surat, report_type_id) 
    }

    return null 
}

const Helper = {
    dateTimeIndo,
    dateIndo,
    dayIndo,
    monthIndo,
    fileSize,
    actionTypeIndo,
    docxEditTextPDF,
    accessRole,
    getTimeAgo,
    getTitleReportType,
    styleExpired,
    fallbackImage_,
    docxEditTextLoop,
    rgba2hex,
    reformatDate,
    shortenWord,
    capitalizeFirstLetter,
    redirectReport,
    isNestedArray,
    findReportSource
}

export default Helper
